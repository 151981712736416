import { yellow } from '@material-ui/core/colors';
import StarsIcon from '@material-ui/icons/Stars';
import React from 'react';
import { Cell } from 'react-table';
import { OrderStatus, ProgressBar } from '../components';
import winBack from '../images/winback-campaign-icon.png';
import { Order } from '../types';
import DriverCell from './DriverCell';

interface Column {
  accessor: any;
  Header: string;
  minWidth?: number;
  expandingCell?: boolean;
  align?: 'center' | 'left' | 'right';
  format?: (value: number) => string;
  Cell?: any;
  show?: boolean;
  filter?: any;
}

const WebColumns: Column[] = [
  {
    accessor: 'orderID',
    Header: 'Order ID',
    minWidth: 100,
  },

  {
    accessor: 'externalOrderID',
    Header: 'External Order ID',
    minWidth: 100,
  },

  {
    accessor: 'orderAmount',
    Header: 'Amount',
    minWidth: 80,
    Cell: (cellInfo: Cell<Order>) => {
      const amount = cellInfo.row.original.orderAmount;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ZAR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return <div>{formatter.format(amount)}</div>;
    },
  },

  {
    accessor: 'status' as keyof Order,
    Header: 'Order Status',
    minWidth: 150,
    filter: 'multiple',
    Cell: (cellInfo: Cell<Order>) => {
      const status = cellInfo.row.original.status;
      return <OrderStatus status={Number(status)} />;
    },
  },
  {
    accessor: 'firstName',
    Header: 'First Name',
    minWidth: 130,
    Cell: (cellInfo: Cell<Order>) => {
      const isVIP = cellInfo.row.original.isVIP;
      const firstName = cellInfo.row.original.firstName;
      const isCampaignCoupon = cellInfo.row.original.isCampaignCoupon;

      if (isVIP && isCampaignCoupon) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              position: 'relative',
            }}
          >
            <StarsIcon style={{ color: yellow[800], position: 'relative' }} />
            <img
              alt="icon"
              src={winBack}
              style={{
                position: 'relative',
                maxWidth: '18%',
                background: 'white',
                borderRadius: '50%',
                border: '1px solid #57A946',
                zIndex: 99,
                right: '12%',
              }}
            />
            {firstName}
          </div>
        );
      } else if (isVIP) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <StarsIcon style={{ color: yellow[800], paddingRight: '5px' }} /> {firstName}
          </div>
        );
      } else if (isCampaignCoupon) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <img
              alt="icon"
              src={winBack}
              style={{
                position: 'relative',
                maxWidth: '18%',
                background: 'white',
                borderRadius: '50%',
                border: '1px solid #57A946',
              }}
            />
            <span style={{ paddingLeft: '5%' }}>{firstName}</span>
          </div>
        );
      } else {
        return firstName;
      }
    },
  },
  { accessor: 'lastName', Header: 'Last Name', minWidth: 130 },
  { accessor: 'totalItems', Header: 'Total Items', minWidth: 70 },
  {
    accessor: 'percent_complete',
    Header: 'Progress',
    minWidth: 80,
    Cell: (cellInfo: Cell<Order>) => {
      const completedItems = cellInfo.row.original.completedItems;
      const totalItems = cellInfo.row.original.totalItems;
      const progress = Math.round((Number(completedItems) / Number(totalItems)) * 100);

      return <ProgressBar completed={progress} />;
    },
  },
  { accessor: 'deliveryFrom', Header: 'Timeslot Start', minWidth: 100 },
  { accessor: 'agent', Header: 'Agent', minWidth: 120 },
  { accessor: 'shopper', Header: 'Shopper', minWidth: 120 },
  {
    accessor: 'driver',
    Header: 'Driver',
    minWidth: 150,
    Cell: (cellInfo: Cell<Order>) => {
      const { driver } = cellInfo?.row?.original ?? '';
      return <DriverCell name={driver} />;
    },
  },
  { accessor: 'shopName', Header: 'Store', minWidth: 150, filter: 'multiple' },
  { accessor: 'startTime', Header: 'Time Started', minWidth: 130, align: 'right' },
  { accessor: 'shoppingCompleted', Header: 'Time Completed', minWidth: 130, align: 'right' },
  { accessor: 'deliveryStarted', Header: 'Delivery Started', minWidth: 130, align: 'right' },
  { accessor: 'deliveryCompleted', Header: 'Delivery Completed', minWidth: 130, align: 'right', expandingCell: true },
  { accessor: 'orderTypeID', Header: 'order type', minWidth: 0, show: false },
  { accessor: 'runningLate', Header: 'runningLate', minWidth: 0, show: false },
];

const TabletColumns: Column[] = [
  {
    accessor: 'orderID',
    Header: 'Order ID',
    minWidth: 50,
  },
  {
    accessor: 'status' as keyof Order,
    Header: 'Order Status',
    minWidth: 100,
    filter: 'multiple',
    Cell: (cellInfo: Cell<Order>) => {
      const status = cellInfo.row.original.status;
      return <OrderStatus status={Number(status)} />;
    },
    show: false,
  },
  {
    accessor: 'firstName',
    Header: 'Name',
    minWidth: 80,
    Cell: (cellInfo: Cell<Order>) => {
      const isVIP = cellInfo.row.original.isVIP;
      const firstName = cellInfo.row.original.firstName;
      const lastName = cellInfo.row.original.lastName;
      const isCampaignCoupon = cellInfo.row.original.isCampaignCoupon;

      if (isVIP && isCampaignCoupon) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              position: 'relative',
            }}
          >
            <StarsIcon style={{ color: yellow[800] }} />
            <img
              alt="icon"
              src={winBack}
              style={{
                position: 'relative',
                maxWidth: '12%',
                background: 'white',
                borderRadius: '50%',
                border: '1px solid #57A946',
                zIndex: 99,
                right: '8%',
              }}
            />
            {`${firstName} ${lastName}`}
          </div>
        );
      } else if (isVIP) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <StarsIcon style={{ color: yellow[800], paddingRight: '5px' }} /> {`${firstName} ${lastName}`}
          </div>
        );
      } else if (isCampaignCoupon) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <img
              alt="icon"
              src={winBack}
              style={{
                position: 'relative',
                maxWidth: '12%',
                background: 'white',
                borderRadius: '50%',
                border: '1px solid #57A946',
              }}
            />
            <span style={{ paddingLeft: '5%' }}>{firstName}</span>
          </div>
        );
      } else {
        return firstName;
      }
    },
    // expandingCell: true
  },
  {
    accessor: 'percent_complete',
    Header: 'Progress',
    minWidth: 80,
    Cell: (cellInfo: Cell<Order>) => {
      const completedItems = cellInfo.row.original.completedItems;
      const totalItems = cellInfo.row.original.totalItems;
      const progress = Math.round((Number(completedItems) / Number(totalItems)) * 100);

      return <ProgressBar completed={progress} />;
    },
  },
  { accessor: 'deliveryFrom', Header: 'Timeslot Start', minWidth: 60 },
  { accessor: 'shopper', Header: 'Shopper', minWidth: 60 },
  { accessor: 'startTime', Header: 'Time Started', minWidth: 70 },
  { accessor: 'shoppingCompleted', Header: 'Time Completed', minWidth: 70 },

  {
    accessor: 'driver',
    Header: 'Driver',
    minWidth: 60,
    Cell: (cellInfo: Cell<Order>) => {
      const { driver } = cellInfo?.row?.original ?? '';
      return <DriverCell name={driver} />;
    },
  },
  { accessor: 'deliveryStarted', Header: 'Delivery Started', minWidth: 70 },
  { accessor: 'deliveryCompleted', Header: 'Delivery Completed', minWidth: 70, expandingCell: true },
  { accessor: 'totalItems', Header: 'Total Items', minWidth: 0, show: false },
  { accessor: 'shopName', Header: 'Store', minWidth: 0, filter: 'multiple', show: false },
  { accessor: 'orderTypeID', Header: 'order type', minWidth: 0, show: false },
  { accessor: 'runningLate', Header: 'runningLate', minWidth: 0, show: false },
];

const MobileColumns: Column[] = [
  {
    accessor: 'orderID',
    Header: 'ID',
    minWidth: 70,
  },
  {
    accessor: 'status' as keyof Order,
    Header: 'Status',
    minWidth: 100,
    filter: 'multiple',
    align: 'center',
    Cell: (cellInfo: Cell<Order>) => {
      const status = cellInfo.row.original.status;
      return <OrderStatus status={Number(status)} />;
    },
  },
  { accessor: 'totalItems', Header: 'Items', minWidth: 60 },
  { accessor: 'deliveryFrom', Header: 'Timeslot', minWidth: 70 },
  { accessor: 'shopName', Header: 'Store', minWidth: 100, filter: 'multiple' },
  { accessor: 'orderTypeID', Header: 'order type', minWidth: 0, show: false },
  { accessor: 'runningLate', Header: 'runningLate', minWidth: 0, show: false },
];

const LandScapeColumns: Column[] = [
  {
    accessor: 'orderID',
    Header: 'ID',
    minWidth: 90,
  },

  {
    accessor: 'firstName',
    Header: 'First Name',
    minWidth: 100,
    Cell: (cellInfo: Cell<Order>) => {
      const isVIP = cellInfo.row.original.isVIP;
      const firstName = cellInfo.row.original.firstName;
      const isCampaignCoupon = cellInfo.row.original.isCampaignCoupon;

      if (isVIP && isCampaignCoupon) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              position: 'relative',
            }}
          >
            <StarsIcon style={{ color: yellow[800], position: 'relative' }} />
            <img
              alt="icon"
              src={winBack}
              style={{
                position: 'relative',
                maxWidth: '30%',
                background: 'white',
                borderRadius: '50%',
                border: '1px solid #57A946',
                zIndex: 99,
                right: '12%',
              }}
            />
            {firstName}
          </div>
        );
      } else if (isVIP) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <StarsIcon style={{ color: yellow[800], paddingRight: '5px' }} /> {firstName}
          </div>
        );
      } else if (isCampaignCoupon) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <img
              alt="icon"
              src={winBack}
              style={{
                position: 'relative',
                maxWidth: '30%',
                background: 'white',
                borderRadius: '50%',
                border: '1px solid #57A946',
              }}
            />
            <span style={{ paddingLeft: '5%' }}>{firstName}</span>
          </div>
        );
      } else {
        return firstName;
      }
    },
    expandingCell: true,
  },
  {
    accessor: 'percent_complete',
    Header: 'Progress',
    minWidth: 105,
    Cell: (cellInfo: Cell<Order>) => {
      const completedItems = cellInfo.row.original.completedItems;
      const totalItems = cellInfo.row.original.totalItems;
      const progress = Math.round((Number(completedItems) / Number(totalItems)) * 100);

      return <ProgressBar completed={progress} />;
    },
  },
  { accessor: 'deliveryFrom', Header: 'Timeslot', minWidth: 95 },
  { accessor: 'shopper', Header: 'Shopper', minWidth: 90 },
  { accessor: 'startTime', Header: 'Time Started', minWidth: 100 },
  { accessor: 'shoppingCompleted', Header: 'Time Completed', minWidth: 100 },
  {
    accessor: 'driver',
    Header: 'Driver',
    minWidth: 100,
    Cell: (cellInfo: Cell<Order>) => {
      const { driver } = cellInfo?.row?.original ?? '';
      return <DriverCell name={driver} />;
    },
  },
  { accessor: 'deliveryStarted', Header: 'Delivery Started', minWidth: 100 },
  { accessor: 'deliveryCompleted', Header: 'Delivery Completed', minWidth: 100, expandingCell: true },

  {
    accessor: 'status' as keyof Order,
    Header: 'Status',
    minWidth: 0,
    show: false,
  },
  { accessor: 'totalItems', Header: 'Total Items', minWidth: 0, show: false },
  { accessor: 'shopName', Header: 'Store', minWidth: 0, filter: 'multiple', show: false },
  { accessor: 'orderTypeID', Header: 'order type', minWidth: 0, show: false },
  { accessor: 'runningLate', Header: 'runningLate', minWidth: 0, show: false },
];

export { WebColumns, TabletColumns, MobileColumns, LandScapeColumns };
