import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React, { Fragment, FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import * as store from 'store';
import {
  // getReceiptData,
  getCustomerDetails,
  getInvoiceData,
  getShoppingList,
} from '../api';
import { BreakPoints } from '../theme/breakPoints';
import { mapProductFulfillment, parseImage } from '../utils/utils';
import FullScreenDialog from './FullScreenDialog';
import Invoice from './Invoice';

interface ReceiptProps {
  orderID: string;
  isCompleted: string;
  orderTypeID: string;
}

declare interface Product {
  productID: number;
  name: string;
  price: number;
  status: number;
  imageURL: string;
  orderQuantity: number;
  suppliedQuantity: number;
  substitutionName: string;
  substitutionID: number;
  substitutionQuantity: number;
  substitutionPrice: number;
  substitutionImageURL?: string;
  vendor: string;
  vendorID?: number;
  note?: string;
  total?: number;
  sku: string;
  weightedItems?: {
    orderID: string;
    originalProductID: number;
    actualProductID: number;
    actualProductName: string;
    barcode: string;
    itemPrice: number;
    unitPrice: number;
    weight: number;
  }[];
}
interface Vendor {
  vendor: string;
  products: Product[];
  cost: string;
}

interface ProductListProps {
  products: Product[];
  substitute?: boolean;
  partial?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      height: '65vh',
      borderRadius: theme.spacing(2),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    container: {
      padding: '0px',
    },
    avatarWrapper: {
      margin: '0px',
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    arrowForward: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(5),
      fontSize: theme.spacing(3),
      textAlign: 'right',
      lineHeight: theme.spacing(3),
    },
    refunded: {
      color: 'red',
    },
    substituted: {
      color: '#f1c40f',
    },
    partial: {
      color: '#45D8E5',
    },
    listItem: {
      display: 'grid',
      padding: '10px 8px',
      alignItems: 'center',
      background: '#E0E0E060',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'auto',
      borderRadius: '4px',
      [theme.breakpoints.up(BreakPoints.sm)]: {
        paddingRight: '20px',
      },
    },
    listItemContentWrapper: {
      display: 'grid',
      paddingLeft: '1.5rem',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
      [theme.breakpoints.up(BreakPoints.sm)]: {
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        gridTemplateRows: 'auto',
      },
    },
    listItemText: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    listItemTextBold: {
      marginTop: '-8px',
      fontSize: '1.15rem',
      fontWeight: 500,
    },
    orderUnavailable: {
      margin: 'auto',
      width: '80%',
      padding: '10px',
      textAlign: 'center',
    },
    substitute: {
      fontSize: theme.spacing(1.5),
    },
    receiptSubheaderWrapper: {
      padding: '0px',
    },
    receiptSubheader: {
      display: 'inline-block',
    },
    printButton: {
      float: 'right',
      margin: theme.spacing(1),
    },
    rows: {
      width: '100%',
    },
    weightedItems: {
      position: 'relative',
      color: '#baa246',
      fontSize: '0.775rem',
      bottom: '25px',
    },
  })
);
const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const ProductList: FunctionComponent<ProductListProps> = (props) => {
  const { products, substitute, partial } = props;
  const classes = useStyles();

  return (
    <List>
      <ListItem alignItems="flex-start" className={classes.container}>
        <TableContainer>
          <Table aria-label="products table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '60%' }}>Product</TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length > 0 &&
                products.map((product) => {
                  let weightedItem;
                  const weightedItems = product.weightedItems;

                  if (weightedItems && weightedItems?.length) {
                    const uniqueProducts = weightedItems.filter((product, index, self) => {
                      return self.findIndex((p) => p.actualProductID === product.actualProductID) === index;
                    });

                    const summedQuantities = uniqueProducts.map((product) => {
                      const matchingProducts = weightedItems.filter(
                        (p) => p.actualProductID === product.actualProductID
                      );

                      const totalWeight = matchingProducts.reduce((sum, p) => sum + p.weight, 0);
                      const quantity = matchingProducts.reduce((sum, p) => sum + 1, 0);

                      return {
                        ...product,
                        weight: totalWeight,
                        quantity,
                      };
                    });

                    weightedItem = summedQuantities.map((item) => {
                      return (
                        <TableRow key={product.productID}>
                          <TableCell style={{ width: '520px' }}>
                            <span className={classes.weightedItems}>{item.actualProductName}</span>
                          </TableCell>
                          <TableCell align="center">
                            <span className={classes.weightedItems}>{item.quantity.toFixed(0)}</span>
                          </TableCell>
                          <TableCell align="right">
                            <span className={classes.weightedItems}>R {(item.weight * item.unitPrice).toFixed(2)}</span>
                          </TableCell>
                        </TableRow>
                      );
                    });
                  }

                  if (substitute) {
                    return (
                      <>
                        <TableRow key={product.productID}>
                          <TableCell style={{ width: '60%' }}>
                            {product.substitutionName} for <br></br>
                            <span className={classes.substitute}> {product.name}</span>
                          </TableCell>
                          <TableCell align="center">{product.substitutionQuantity}</TableCell>
                          <TableCell align="right">R {product.substitutionPrice.toFixed(2)}</TableCell>
                        </TableRow>
                        {weightedItem ? weightedItem : ''}
                      </>
                    );
                  } else {
                    return (
                      <>
                        <TableRow key={product.productID}>
                          <TableCell style={{ width: '60%' }}>{product.name}</TableCell>
                          <TableCell align="center">
                            {partial ? `${product.suppliedQuantity}/${product.orderQuantity}` : product.orderQuantity}
                          </TableCell>
                          <TableCell align="right">R {product.price.toFixed(2)}</TableCell>
                        </TableRow>
                        {weightedItem ? weightedItem : ''}
                      </>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </ListItem>
    </List>
  );
};

const Receipt: FunctionComponent<ReceiptProps> = (props) => {
  const { orderID, isCompleted } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let vendors = [];
  let customerData = { customerEmail: '' };
  const accessToken = store.get('spotlightAccessToken');
  const { data } = useQuery(['receiptShopping', accessToken, orderID], () => getShoppingList({ accessToken, orderID }));
  const { data: invoiceData } = useQuery(['invoiceData', accessToken, orderID], () =>
    getInvoiceData({ accessToken, orderID })
  );

  if (data?.data) {
    vendors = data?.data.details;
  }

  const { data: customerDetails } = useQuery(['customerDetails', accessToken, orderID], () =>
    getCustomerDetails({ accessToken, orderID })
  );

  if (customerDetails?.data) {
    customerData = customerDetails?.data.details[0];
  }

  // const { data: PrintData } = useQuery(
  //   ['printData', accessToken, orderID],
  //   () => getReceiptData({ accessToken, orderID, email: customerData.customerEmail }),
  //   {
  //     enabled: !!(vendors.length && customerData.customerEmail),
  //   }
  // );

  const handleClick = () => {
    if (invoiceData?.data.details) {
      setOpen(!open);
    }
  };
  return (
    <Fragment>
      <Paper style={{ maxHeight: '100%', overflow: 'auto', borderRadius: '20px' }}>
        <List
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader" className={classes.receiptSubheaderWrapper}>
              <Typography className={classes.receiptSubheader}>Receipt</Typography>
              {Number(isCompleted) === 1 && Boolean(invoiceData?.data?.details) && (
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.printButton}
                  startIcon={<ReceiptIcon color="primary" />}
                  onClick={handleClick}
                >
                  Print
                </Button>
              )}
            </ListSubheader>
          }
          className={classes.root}
        >
          {Number(isCompleted) === 1 ? (
            <Fragment>
              {vendors.length > 0 &&
                vendors.map((vendor: Vendor) => {
                  let imageUrl = '';
                  if (vendor.products.length > 0) {
                    imageUrl = parseImage(vendor.vendor);
                  }
                  let products: Product[] = vendor?.products;
                  const groupedProducts = mapProductFulfillment(products, vendor.vendor);

                  return (
                    <Fragment>
                      <ListItem alignItems="flex-start" className={classes.listItem}>
                        <ListItemAvatar className={classes.avatarWrapper}>
                          <Avatar className={classes.avatar} alt={`${vendor.vendor} logo`} src={`${imageUrl}`} />
                        </ListItemAvatar>
                        <div className={classes.listItemContentWrapper}>
                          <ListItemText
                            className={classes.listItemText}
                            secondary={`${vendor.products.length} items`}
                            primary={vendor.vendor}
                          />
                          <ListItemText classes={{ primary: classes.listItemTextBold }} primary={vendor.vendor} />
                        </div>
                      </ListItem>
                      {groupedProducts.foundItems.length > 0 && (
                        <Fragment>
                          <ListSubheader disableSticky color="primary">
                            Found Items
                          </ListSubheader>
                          <Divider />
                          <ProductList products={groupedProducts.foundItems} />
                        </Fragment>
                      )}
                      {groupedProducts.partialItems.length > 0 && (
                        <Fragment>
                          <ListSubheader classes={{ root: classes.partial }} disableSticky color="primary">
                            Partial Items
                          </ListSubheader>
                          <Divider />
                          <ProductList partial={true} products={groupedProducts.partialItems} />
                        </Fragment>
                      )}
                      {groupedProducts.substitutedItems.length > 0 && (
                        <Fragment>
                          <ListSubheader classes={{ root: classes.substituted }} disableSticky color="primary">
                            Substituted Items
                          </ListSubheader>
                          <Divider />
                          <ProductList substitute={true} products={groupedProducts.substitutedItems} />
                        </Fragment>
                      )}
                      {groupedProducts.outOfStockItems.length > 0 && (
                        <Fragment>
                          <ListSubheader classes={{ root: classes.refunded }} disableSticky color="primary">
                            Refunded
                          </ListSubheader>
                          <Divider />
                          <ProductList products={groupedProducts.outOfStockItems} />
                        </Fragment>
                      )}
                    </Fragment>
                  );
                })}
            </Fragment>
          ) : (
            <Fragment>
              <Typography className={classes.orderUnavailable} variant="h5" gutterBottom>
                Receipt will be available when the order is completed
              </Typography>
            </Fragment>
          )}
        </List>
      </Paper>
      {open && (
        <FullScreenDialog closeModal={handleClick} title="Invoice" openModal={open}>
          <Invoice customerData={customerData} data={invoiceData} />
        </FullScreenDialog>
      )}
    </Fragment>
  );
};

export default Receipt;
