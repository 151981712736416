import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useAnalyticsStyles } from './Styles';

declare interface CardProps {
  caption: string;
  value: string;
}

function SummaryCard(props: CardProps) {
  const { caption, value } = props;
  const classes = useAnalyticsStyles();
  return (
    <div className={classes.summaryCard}>
      <div className={classes.summaryCardCaption}>
        <Typography className={classes.summaryCaptionText} noWrap>
          {caption}
        </Typography>
      </div>
      <div className={classes.summaryCardValue}>{value}</div>
    </div>
  )
}

export default SummaryCard
