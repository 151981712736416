import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const Product = ({ classes, itemDetails, statusColor }) => {
  const styling = useStyles();
  return (
    <div className={styling.root}>
      <Accordion style={{ backgroundColor: statusColor }}>
        <AccordionSummary
          aria-label="Expand"
          expandIcon={statusColor === 'yellow' ? <ExpandMoreIcon /> : null}
          aria-controls="panel1bh-content"
          id={itemDetails.productID}
        >
          <ListItem
            alignItems="flex-start"
            onClick={statusColor === 'yellow' ? null : (event) => event.stopPropagation()}
            onFocus={statusColor === 'yellow' ? null : (event) => event.stopPropagation()}
          >
            <ListItemAvatar>
              <Avatar alt="Product image" src={itemDetails.imageURL} className={classes.avatar} />
            </ListItemAvatar>
            <ListItemText
              className={classes.listItem}
              primary={itemDetails.name}
              secondary={
                <React.Fragment>
                  <Typography gutterBottom variant="body2" color="textPrimary">
                    Quantity: {itemDetails.orderQuantity}
                  </Typography>
                  <Typography gutterBottom variant="body2" color="textPrimary">
                    Price: R {itemDetails.price}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </AccordionSummary>
        <AccordionDetails>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Product image" src={itemDetails.substitutionImageURL} className={classes.avatar} />
            </ListItemAvatar>
            <ListItemText
              className={classes.listItem}
              primary={itemDetails.substitutionName}
              secondary={
                <React.Fragment>
                  <Typography gutterBottom variant="body2" color="textPrimary">
                    Quantity: {itemDetails.substitutionQuantity}
                  </Typography>
                  <Typography gutterBottom variant="body2" color="textPrimary">
                    Price: R {itemDetails.substitutionPrice}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Product;
