import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { Notifications } from '../types';

const useStyles = makeStyles((theme) => ({
    container: {
      width: '25vw',
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    notificationsHeader: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '50px',
    },
    backIcon: {
      flex: 1,
      color: '#57A946',
      marginLeft: theme.spacing(1),
      fontSize: '36px',
    },
    notificationTitle: {
      flex: 6,
      fontFamily: 'arial, sans-serif',
      fontSize: '26px',
      textAlign: 'center',
      color: '#57A946',
    },
    bellIcon: {
      // flex: 1,
      float: 'right',
      fontFamily: 'arial, sans-serif',
      fontSize: '26px',
      marginRight: theme.spacing(4),
      color: '#57A946',
    },
    notificationContainer: {
      display: 'flex',
      margin: '1px auto 1px auto',
      minHeight: '50px',
      borderRadius: '3px 3px 3px 3px',
      boxShadow: '0 0 2px #999',
      opacity: '0.9',
      backgroundRepeat: 'no-repeat',
    },
    isRead: {
      color: '#444',
      background: '#fff',
    },
    unRead: {
      color: '#111',
      background: '#EEF6EC',
    },
    info: {
      flex: 1,
      padding: '10px 10px 0 5%',
    },
    h1: {
      margin: 0,
      padding: 0,
      fontFamily: 'arial, sans-serif',
      fontSize: '16px',
    },
    a: {
      textDecoration: 'none',
      color: '#444',
    },
    p: {
      margin: 0,
      padding: '5px 0',
      fontFamily: 'arial, sans-serif',
      fontSize: '14px',
      color: '#3d3d3d',
    },
    timeStamp: {
      display: 'inline-block',
      margin: '3px 3px 5px 0',
      padding: '3px 0px',
      fontFamily: 'arial, sans-serif',
      fontSize: '12px',
      fontWeight: 'bold',
      textDecoration: 'none',
      color: '#ecf0f1',
    },
    gray: {
      color: '#95a5a6',
    },
    imageContainer: {
      padding: '6% 3% 0 6%',
    },
    image: {
      width: 30,
      height: 30,
    }
  }));

export default function NotificationList({ notifications, toggleDrawer, handleClick }: {
    notifications: Notifications;
    toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    handleClick: (e: React.MouseEvent, orderID: string) => void;
}) {
    const classes = useStyles();
  return (
    <div
      className={classes.container}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.notificationsHeader}>
        <KeyboardBackspaceIcon className={classes.backIcon} onClick={toggleDrawer(false)} />
        <div className={classes.notificationTitle} onClick={toggleDrawer(false)}>
          notifications
        </div>
        <Badge overlap="rectangular" badgeContent={notifications.unread} color="error" className={classes.bellIcon}>
          <NotificationsIcon onClick={toggleDrawer(false)} />
        </Badge>
      </div>
      <Divider />
      {notifications.list?.map((notification) => (
        <div
          className={`${classes.notificationContainer} ${notification.isRead ? classes.isRead : classes.unRead}`}
          key={notification?.orderID}
          onClick={(e) => handleClick(e, notification?.orderID)}
        >
          <div className={classes.imageContainer}>
            <img className={classes.image} src={notification.icon} alt="" />
          </div>
          <div className={classes.info}>
            <h1 className={classes.h1}>{notification?.title}</h1>
            <p className={classes.p}>{notification?.message}</p>
            <p className={`${classes.timeStamp} ${classes.gray}`}>{notification?.date}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
