import React, { FunctionComponent, useState } from 'react';
// import { ChatContext } from '../contexts/ChatContext';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelIcon from '@material-ui/icons/Cancel';
import ShippingIcon from '@material-ui/icons/LocalShipping';
import PauseIcon from '@material-ui/icons/Pause';
import PersonIcon from '@material-ui/icons/PersonOutline';
import ResetIcon from '@material-ui/icons/RotateLeft';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StoreIcon from '@material-ui/icons/Storefront';
import Modify from '@material-ui/icons/EditOutlined';
import clsx from 'clsx';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as store from 'store';
import {
  assignOrder,
  cancelOrder,
  getOrderActivityHistory,
  holdOrder,
  postToLoop,
  postToPicup,
  postToWumdrop,
} from '../api';
import { usePermissions } from '../hooks/usePermissions';
import { SpotlightPermission } from '../types/enums';
import ChangeStore from './ChangeStore';
import RescheduleOrder from './RescheduleOrder';
import ResetOrder from './ResetOrder';
import { createToastMessage } from './toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(2),
      backgroundColor: '#F4F4F4',
      verticalAlign: 'middle',
      maxHeight: '100%',
      flexWrap: 'wrap',
    },

    button: {
      height: 45,
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      fontWeight: 450,
    },

    wrapper: {
      float: 'right',
      margin: theme.spacing(3),
      marginLeft: 'auto',
    },

    backButton: {
      color: '#21212190',
      background: '#BDBDBD70',
      border: 'none',
    },

    colorGreen: {
      color: 'white',
      background: theme.palette.success.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.success.dark,
        border: `1px solid ${theme.palette.success.dark}`,
        transform: 'scale(1.0)',
      },
    },

    colorBlue: {
      color: 'white',
      background: theme.palette.info.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.info.dark,
        border: `1px solid ${theme.palette.info.dark}`,
        transform: 'scale(1.0)',
      },
    },

    colorOrange: {
      color: 'white',
      background: theme.palette.warning.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.warning.dark,
        border: `1px solid ${theme.palette.warning.dark}`,
        transform: 'scale(1.0)',
      },
    },

    colorRed: {
      color: 'white',
      background: theme.palette.error.dark,
      border: 'none',
      '&:hover': {
        color: theme.palette.error.dark,
        border: `1px solid ${theme.palette.error.dark}`,
        transform: 'scale(1.0)',
      },
    },
    disabled: {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.action.disabled,
      //   color: rgba(0, 0, 0, 0.26);
      // background-color: rgba(0, 0, 0, 0.12);
    },
  })
);

interface ActionButtonsProps {
  orderID: string;
  shopper: string;
  onModifyOrder?: () => void;
}

//  Un-comment Chat when chat it ready

const OrderActionButtons: FunctionComponent<ActionButtonsProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  // const { setChannel, setAssigned, setCurrentOrderID } = useContext(ChatContext);
  const [openChangeStore, setOpenChangeStore] = useState(false);
  const [openReschedule, setOpenReschedule] = useState(false);
  const [openResetOrder, setOpenResetOrder] = useState(false);
  const { isPermitted } = usePermissions();

  const accessToken = store.get('spotlightAccessToken');
  const { orderID, shopper } = props;

  const { refetch: refetchActivityHistory } = useQuery(['activityHistory', accessToken, orderID], () =>
    getOrderActivityHistory({ accessToken, orderID })
  );

  const handleCloseChangeStore = () => setOpenChangeStore(false);
  const handleOpenChangeStore = () => setOpenChangeStore(true);
  const handleCloseReschedule = () => setOpenReschedule(false);
  const handleOpenReschedule = () => setOpenReschedule(true);
  const handleOpenResetOrder = () => setOpenResetOrder(true);
  const handleCloseResetOrder = () => setOpenResetOrder(false);

  const assignOrderMutation = useMutation(assignOrder);
  const postToPicupMutation = useMutation(postToPicup);
  const postToWumdropMutation = useMutation(postToWumdrop);
  const postToLoopMutation = useMutation(postToLoop);
  const cancelOrderMutation = useMutation(cancelOrder);
  const holdOrderMutation = useMutation(holdOrder);

  const userCanCancelOrder = isPermitted([SpotlightPermission.DELETE_ORDER]);
  const userCanResetOrder = isPermitted(
    [SpotlightPermission.RESET_ORDER, SpotlightPermission.RESET_ORDER_HIGH_RISK, SpotlightPermission.RESET_ORDER_ADMIN],
    'any'
  );
  const userCanEdit = isPermitted([SpotlightPermission.EDIT_ORDER]);
  const userCanCreateDelivery = isPermitted([SpotlightPermission.CREATE_PICUP]);

  const handleAssignOrder = () => {
    if (accessToken) {
      assignOrderMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleHoldOrder = () => {
    if (accessToken) {
      holdOrderMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handlePicup = () => {
    if (accessToken) {
      postToPicupMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleWumdrop = () => {
    if (accessToken) {
      postToWumdropMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };
  const handleLoop = () => {
    if (accessToken) {
      postToLoopMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleCancelOrder = () => {
    if (accessToken) {
      cancelOrderMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleModifyOrder = () => {
    if (props.onModifyOrder) {
      props.onModifyOrder();
    }
  };

  const actionButtons = [
    {
      label: 'Assign to me',
      icon: <PersonIcon />,
      color: classes.colorGreen,
      action: handleAssignOrder,
      enabled: true,
    },
    { label: 'Modify Order', icon: <Modify color="primary" />, action: handleModifyOrder, enabled: userCanEdit },
    { label: 'Reschedule', icon: <ScheduleIcon color="primary" />, action: handleOpenReschedule, enabled: true },
    { label: 'Change Store', icon: <StoreIcon color="primary" />, action: handleOpenChangeStore, enabled: userCanEdit },
    {
      label: 'Post to Wumdrop',
      icon: <ShippingIcon color="primary" />,
      action: handleWumdrop,
      enabled: userCanCreateDelivery,
    },
    {
      label: 'Post to Loop',
      icon: <ShippingIcon color="primary" />,
      action: handleLoop,
      enabled: userCanCreateDelivery,
    },
    {
      label: 'Post to Picup',
      icon: <ShippingIcon color="primary" />,
      action: handlePicup,
      enabled: userCanCreateDelivery,
    },
    { label: 'Hold Order', icon: <PauseIcon />, color: classes.colorBlue, action: handleHoldOrder, enabled: true },
    {
      label: 'Reset Order',
      icon: <ResetIcon />,
      color: classes.colorOrange,
      action: handleOpenResetOrder,
      enabled: userCanResetOrder,
    },
    {
      label: 'Cancel Order',
      icon: <CancelIcon />,
      color: classes.colorRed,
      action: handleCancelOrder,
      enabled: userCanCancelOrder,
    },
  ];

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIosIcon />}
          className={`${classes.button} ${classes.backButton}`}
          onClick={() => history.push('/orders')}
          size="large"
        >
          Back
        </Button>
        <div className={classes.wrapper}>
          {actionButtons.map(({ label, icon, color, action, enabled }) => (
            <Button
              className={clsx(classes.button, enabled ? color : classes.disabled)}
              disabled={!enabled}
              variant="outlined"
              startIcon={icon}
              onClick={action}
              size="large"
            >
              {label}
            </Button>
          ))}
        </div>
      </Card>

      {openReschedule && (
        <RescheduleOrder
          orderID={orderID}
          open={openReschedule}
          closeReschedule={handleCloseReschedule}
          refetchActivityHistory={refetchActivityHistory}
        />
      )}
      {openResetOrder && (
        <ResetOrder
          orderID={orderID}
          open={openResetOrder}
          closeResetOrder={handleCloseResetOrder}
          refetchActivityHistory={refetchActivityHistory}
          shopper={shopper}
        />
      )}

      {openChangeStore && (
        <ChangeStore
          orderID={orderID}
          open={openChangeStore}
          closeChangeStore={handleCloseChangeStore}
          refetchActivityHistory={refetchActivityHistory}
        />
      )}
    </React.Fragment>
  );
};

export default OrderActionButtons;
