import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';
import { createBrowserHistory } from 'history';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
// import { ChatProvider } from './contexts/ChatContext';
import { Route, Router, Switch } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
import UserRole from './components/UserRole';
import Analytics from './pages/Analytics';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Order from './pages/Order';
import ShoppingList from './pages/ShoppingList';
import { OrdersProvider } from './contexts/OrdersContext';
import * as store from 'store';
import { ModalProvider } from 'contexts/ModalContext';

const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: {
      main: '#57A946',
    },
  },
  typography: {
    fontFamily: ['Rubik'].join(','),
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const App = () => {
  const { error } = useAuth0();
  const history = createBrowserHistory();

  if (error) return <div>Oops... {error.message}</div>;

  const appDomain = process.env.REACT_APP_AUTH0_APP_DOMAIN ?? '';
  const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';

  // reserve potential deeplink
  const pathname = window.location.pathname.slice(1);

  const excludePaths = ['orders', 'analytics', ''];

  if (!excludePaths.includes(pathname)) {
    store.set('deeplink', pathname);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={appDomain}
          clientId={clientID}
          redirectUri={window.location.origin}
          useRefreshTokens={true}
          cacheLocation="localstorage"
          scope="openid email profile"
        >
          {/* Un-comment Chat when chat is ready */}
          {/* <ChatProvider> */}
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Login} />
              <OrdersProvider>
                <ModalProvider>
                  <ProtectedRoute exact path="/orders" component={() => UserRole('orders')} />
                  <ProtectedRoute exact path="/my-orders" component={() => UserRole('my-orders')} />
                  <ProtectedRoute exact path="/action-orders" component={() => UserRole('action-orders')} />
                  <ProtectedRoute exact path="/shopper-statuses" component={() => UserRole('shopper-statuses')} />
                  <ProtectedRoute exact path="/mall-slots" component={() => UserRole('mall-slots')} />
                  <ProtectedRoute exact path="/orders/:orderID" component={Order} />
                  <ProtectedRoute exact path="/orders/:orderID/:vendor" component={ShoppingList} />
                  <ProtectedRoute exact path="/analytics" component={Analytics} />
                </ModalProvider>
              </OrdersProvider>
              <Route component={NotFound} />
            </Switch>
          </Router>
          {/* </ChatProvider> */}
        </Auth0Provider>
      </ThemeProvider>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </QueryClientProvider>
  );
};

export default App;
