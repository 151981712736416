import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import * as store from 'store';
import { getActionOrders } from '../api';
import EmptyState from '../components/empty';
import { useColumns } from '../hooks/useColumns';
import { Order, OrderSummary } from '../types';
import { groupOrders } from '../utils/utils';
import VirtualizedTable from './VirtualTable';

interface TableProps {
  orders: Order[];
  setActionOrdersSummary: React.Dispatch<React.SetStateAction<OrderSummary>>;
  startDate: string;
  endDate: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '69vh',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '69vh',
      },
    },
  })
);

const ActionOrdersTable = React.forwardRef((props: TableProps, ref: any) => {
  const { columns } = useColumns();
  const history = useHistory();
  const classes = useStyles();

  useImperativeHandle(ref, () => instance);

  const accessToken = store.get('spotlightAccessToken');
  const { orders, startDate, endDate, setActionOrdersSummary } = props;

  const { data, isLoading } = useQuery(['actionOrders', accessToken, startDate, endDate], () =>
    getActionOrders({ accessToken, startDate, endDate })
  );

  const filterTypes = useMemo(
    () => ({
      multiple: (rows: any, id: any, filterValue: string) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
    }),
    []
  );

  const filterOrders = useCallback((orders: any, actionOrders: any) => {
    if (!orders || orders?.length === 0 || !actionOrders || actionOrders?.length === 0) return [];
    return orders?.filter((o: any) => actionOrders.includes(o.orderID));
  }, []);

  const allOrders = useMemo(() => filterOrders(orders, data?.data.details), [filterOrders, orders, data?.data.details]);
  const allColums = useMemo(() => columns, [columns]);

  const instance = useTable(
    {
      columns: allColums,
      data: allOrders,
      filterTypes,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: allColums.filter((col: any) => col.show === false).map((col: any) => col.accessor),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const { flatRows } = instance;

  useEffect(() => {
    const ActionOrdersSummary = groupOrders(flatRows);
    setActionOrdersSummary(ActionOrdersSummary);
  }, [flatRows, setActionOrdersSummary]);

  const viewOrderDetails = (
    orderID: string,
    externalOrderID: string,
    shopper: string,
    isCompleted: string,
    customerType: string,
    orderTypeID: string,
    shopName: string,
    startTime: string,
    shoppingCompleted: string,
    deliveryStarted: string,
    deliveryCompleted: string,
    agentID: string,
    driver: string,
    firstItemPickedTime: string | null
  ) => {
    history.push(`/orders/${orderID}`, {
      externalOrderID,
      shopper,
      isCompleted,
      customerType,
      orderTypeID,
      shopName,
      startTime,
      shoppingCompleted,
      deliveryStarted,
      deliveryCompleted,
      agentID,
      driver,
      firstItemPickedTime,
    });
  };

  if (isLoading) return <EmptyState loading title="Loading Orders..." />;

  return (
    <Paper className={classes.root}>
      <VirtualizedTable instance={instance} onClickRow={viewOrderDetails} />
    </Paper>
  );
});

export default ActionOrdersTable;
