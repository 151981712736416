import React, { useState, useMemo } from 'react';
import * as store from 'store';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationList from './NotificationList';
import { NotificationType, Notifications } from '../types';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '25vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  openToggleButton: {
    marginTop: '3px',
    marginRight: theme.spacing(2),
    color: '#57A946',
  },
}));

export default function NotificationDrawer() {
  const classes = useStyles();
  const history = useHistory();
  const getNotifications = store.get('notifications');
  const parseNotifications: NotificationType[] = getNotifications ? JSON.parse(getNotifications) : [];

  const [anchor, setAnchor] = useState(false);
  const [notificationsArray, setNotificationsArray] = useState(parseNotifications)
  
  
  const notifications: Notifications = useMemo(() => {
    // get sum of unread messages
    const unread = notificationsArray.reduce((accumulator: number, notification: NotificationType) => {
      if (!notification.isRead) return accumulator + 1;
      return accumulator;
    }, 0);

    return {
      unread,
      list: notificationsArray.sort((a, b) => (a.isRead < b.isRead) ? -1 : 0) // sort by isRead
    }
    
  }, [notificationsArray])

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setAnchor(open);
  };

  const handleClick = (e: React.MouseEvent, orderID: string) => {
    e.preventDefault();

    const newList: NotificationType[] = notifications.list.map((item) => {
      if (item.orderID === orderID) item.isRead = true;
      return item;
    });

    //update list
    setNotificationsArray(newList);
    store.set('notifications', JSON.stringify(newList));

    history.push(`/orders/${orderID}`);
  };

  return (
    <div>
      <React.Fragment>
        <Badge overlap="rectangular" badgeContent={notifications.unread} color="error" className={classes.openToggleButton}>
          <NotificationsIcon onClick={toggleDrawer(true)} />
        </Badge>
        <Drawer anchor="left" open={anchor} onClose={toggleDrawer(false)}>
          <NotificationList notifications={notifications} toggleDrawer={toggleDrawer} handleClick={handleClick}/>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
