import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import { OrderSummary } from '../../types';
import MobileStatusSelect from './MobileStatusSelect';
import MobileVendorFilter from './MobileVendorFilter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(1),
      [theme.breakpoints.down(768)]: {
        marginTop: theme.spacing(0),
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRigt: theme.spacing(1),
      },
      [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRigt: theme.spacing(1),
      },
    },
    card: {
      minWidth: '165px',
      minHeight: '165px',
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down(768)]: {
        Height: 'auto',
        minHeight: '105px',
        minWidth: '40px',
      },
      [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
        Height: 'auto',
        minHeight: '85px',
        minWidth: '40px',
      },
    },
    title: {
      fontSize: 50,
      textAlign: 'center',
      [theme.breakpoints.down(768)]: {
        fontSize: 18,
      },
      [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
        fontSize: 18,
      },
    },
    caption: {
      textAlign: 'center',
      [theme.breakpoints.down(768)]: {
        fontSize: 12,
      },
      [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
        fontSize: 12,
      },
    },
    paper: {
      display: 'flex',
      padding: 16,
      [theme.breakpoints.down(768)]: {
        padding: 0,
      },
      [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
        padding: 0,
      },
    },
  })
);

enum SpotlightPermission {
  DELETE_ORDER = 'delete_order',
  EDIT_ORDER = 'edit_order',
  ASSIGN_AGENT = 'assign_agent',
  CREATE_PICUP = 'create_picup',
  SPOTLIGHT = 'spotlight',
  VENDOR_SWITCH = 'vendor_switch',
  VENDOR_TOGGLE = 'vendor_toggle',
  ORDER_WEBHOOK = 'order_webhook',
  HOLD_ORDER = 'hold_order',
  UPDATE_PRICE = 'update_price',
  SEE_ALL = 'see_all',
  FILTER_DATE = 'filter_date',
  FILTER_SHOPS = 'filter_shops',
  AUTO_ASSIGN = 'auto_assign',
  ALL_SHOPS = 'all_shops',
  RESET_ORDER = 'reset_order',
}
interface OrdersSummaryProps {
  ordersSummary: OrderSummary;
  lateOrders: number;
}

const MobileOrdersSummary: FunctionComponent<OrdersSummaryProps> = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  const seeAll = permissions.includes(SpotlightPermission.SEE_ALL);
  const { ordersSummary } = props;

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item sm={2} xs={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h2" style={{ color: '#CB474F' }} className={classes.title} gutterBottom>
              {ordersSummary.totalOrders}
            </Typography>
            <Typography className={classes.caption}>Total Orders</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={2} xs={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h2" style={{ color: '#11B0F2' }} className={classes.title} gutterBottom>
              {ordersSummary.inProgress}
            </Typography>
            <Typography className={classes.caption}>In Progress</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={2} xs={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h2" style={{ color: '#85CC66' }} className={classes.title} gutterBottom>
              {ordersSummary.totalCompleted}
            </Typography>
            <Typography className={classes.caption}>Completed</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={2} xs={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h2" style={{ color: '#EEAA23' }} className={classes.title} gutterBottom>
              {ordersSummary.notStarted}
            </Typography>
            <Typography className={classes.caption}>Not Started</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={2} xs={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h2" style={{ color: '#D65745' }} className={classes.title} gutterBottom>
              {ordersSummary.completedLate}
            </Typography>
            <Typography className={classes.caption}>Completed Late</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={2} xs={4}>
        <Card className={classes.card} style={{ backgroundColor: '#D65745' }}>
          <CardContent>
            <Typography variant="h2" style={{ color: '#FFF' }} className={classes.title} gutterBottom>
              {ordersSummary.runningLate}
            </Typography>
            <Typography className={classes.caption} style={{ color: '#FFF' }}>
              Running Late
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item sm={12} xs={12}>
        <Paper className={classes.paper}>
          <MobileStatusSelect />

          {seeAll && <MobileVendorFilter />}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MobileOrdersSummary;
