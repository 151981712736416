import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import { useColumns } from '../hooks/useColumns';

const itemKey = (index: number, data: any) => data.items[index].id;

//generate grid-template-columns css values for table rows to position each cell
export const generateColumns = (width: number = 0, columns: any[]) => {
  const totalColumnWidth = columns.reduce((acc: any, col: any) => {
    return (acc += col.minWidth);
  }, 0);

  if (!width || width === 0) width = totalColumnWidth;

  const viewPort = width - getScrollbarWidth();

  const templateColumns = columns.map((column) => {
    const colMinWidth = column?.minWidth;
    const colWidth = parseFloat(`${colMinWidth * (viewPort / totalColumnWidth)}`);
    return `${colWidth.toFixed(2)}px`;
  });

  return templateColumns.join(' ');
};

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer?.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

const useTableStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
  },
  tbody: {
    width: '100%',
    height: 'calc(69vh - 82px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(69vh - 62px)',
    },
  },
  header: {
    height: '80px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: '#fafafa',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
  },
  row: {
    border: 'none',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  cell: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  grid: {
    display: 'grid',
    alignItems: 'center',
    verticalAlign: 'middle',
    gridTemplateRows: 'auto',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    gridTemplateColumns: ({ template }: any) => `${template}`,
  },
  tooltip: {
    fontSize: 14,
  },
}));

const Row = ({ index, style, data, prepareRow, onClickRow, rowSize }: any) => {
  const row = data.items[index];

  const { classes } = data;

  prepareRow(row);

  const viewOrder = () =>
    onClickRow(
      row.original.orderID,
      row.original.externalOrderID,
      row.original.shopper,
      row.original.isCompleted,
      row.original.customerType,
      row.original.orderTypeID,
      row.original.shopName,
      row.original.startTime,
      row.original.shoppingCompleted,
      row.original.deliveryStarted,
      row.original.deliveryCompleted,
      row.original.agentID,
      row.original.driver,
      row.original.firstItemPickedTime
    );

  if (row.original.runningLate && row.original.magic.length === 0) {
    row.original.magic = [
      {
        title: 'Order is Running Late',
        column: 'deliveryFrom',
        color: '#F3CCC7',
      },
    ];
  }

  if (row.original.completedLate && row.original.magic.length === 0) {
    row.original.magic = [
      {
        title: 'Order was Completed Late',
        column: 'deliveryCompleted',
        color: '#F3CCC7',
      },
    ];
  }

  const getCellHighlightingRules = (cell: any) => {
    const rule = row.original.magic.find((x: any) => x.column === cell.column.id);
    const style = { backgroundColor: rule?.color ?? 'transparent', height: '100%' };
    return { style, title: rule?.title };
  };

  return (
    <div style={style}>
      <TableRow
        {...row.getRowProps()}
        onClick={viewOrder}
        style={{ height: rowSize }}
        className={`${classes.row} ${classes.grid}`}
        component="div"
        tabIndex={-1}
        hover
      >
        {row.cells.map((cell: any, index: number) => {
          const rule = getCellHighlightingRules(cell);
          return (
            <Tooltip
              key={index}
              title={rule.title || ''}
              disableHoverListener={!rule.title}
              className={classes.cell}
              classes={{ tooltip: classes.tooltip }}
            >
              <TableCell
                {...cell.getCellProps()}
                className={`${classes.row} ${classes.cell}`}
                style={rule.style}
                component="div"
                variant="body"
              >
                {cell.render('Cell')}
              </TableCell>
            </Tooltip>
          );
        })}
      </TableRow>
    </div>
  );
};

const TableHeader = (props: any) => {
  const { headerGroups, classes } = props;
  return (
    <TableHead component="div">
      {headerGroups.map((group: any) => (
        <TableRow {...group.getHeaderGroupProps()} className={`${classes.grid} ${classes.header}`} component="div">
          {group.headers.map((column: any) => (
            <TableCell
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={classes.row}
              component="div"
              variant="head"
              scope="col"
            >
              {column.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

const VirtualizedTable = (props: any) => {
  const { instance, onClickRow } = props;

  const { columns, rowSize } = useColumns();

  const [templateColumns, setRowColumns] = useState('');

  const tableContainerRef = useRef<HTMLInputElement>(null);

  const { rows, getTableProps, getTableBodyProps, headerGroups, prepareRow } = instance;

  const classes = useTableStyles({ template: templateColumns });

  //react-window: list data should be an object.
  const data = useMemo(() => ({ classes, items: rows }), [classes, rows]);

  useEffect(() => {
    const rect = tableContainerRef?.current?.getBoundingClientRect();
    const template = generateColumns(rect?.width, columns);
    setRowColumns(template);
  }, [columns]);

  return (
    <TableContainer className={classes.container} ref={tableContainerRef}>
      <Table {...getTableProps()} component="div" stickyHeader aria-label="sticky table">
        <TableHeader headerGroups={headerGroups} classes={classes} template={templateColumns} />

        <TableBody component="div" className={classes.tbody} {...getTableBodyProps()}>
          <AutoSizer>
            {({ height, width }: any) => {
              if (!height || height === 0) height = 560;
              if (!width || width === 0) width = '100%';
              return (
                <List
                  width={width}
                  height={height}
                  className={'react-window-list'}
                  itemCount={rows.length}
                  itemSize={rowSize}
                  itemKey={itemKey}
                  overscanCount={10}
                  itemData={data}
                >
                  {(rowProps) => {
                    const allRowProps = { ...rowProps, data, prepareRow, onClickRow };
                    return <Row {...allRowProps} rowSize={rowSize} />;
                  }}
                </List>
              );
            }}
          </AutoSizer>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VirtualizedTable;
