import React from 'react';
import { Dialog, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useModalContext } from 'contexts/ModalContext';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '12px',
  },
  paper: {
    background: 'white',
    padding: theme.spacing(2),
    boxShadow: 'none',
    width: '392px',
    height: '243px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    margin: theme.spacing(0.5, 0),
    fontSize: '16px',
  },
  gif: {
    width: '100px',
    height: '100px',
  },
}));

const GifLoader: React.FC = () => {
  const { loaderModal, setLoaderModal } = useModalContext();
  const classes = useStyles();

  return (
    <Dialog
      open={loaderModal}
      onClose={() => setLoaderModal(false)}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <Paper className={classes.paper}>
        <img src="/static/requestLoader.gif" alt="Loading..." className={classes.gif} />
        <Typography className={classes.text} variant="h6">
          Sending your request
        </Typography>
        <Typography className={classes.text}>Hold on a little</Typography>
      </Paper>
    </Dialog>
  );
};

export default GifLoader;
