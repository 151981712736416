import React, { useImperativeHandle, useEffect, useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { Order, OrderSummary } from '../../types';
import { groupOrders } from '../../utils/utils';
import { WebColumns, MobileColumns, LandScapeColumns } from '../../components';
import VirtualizedTable from '../VirtualTable';

interface TableProps {
  orders: Order[];
  setOrdersSummary: React.Dispatch<React.SetStateAction<OrderSummary>>;
  isMobile: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '69vh',
      overflowX: 'auto',
      fontSize: '4px !important',
      [theme.breakpoints.down(768)]: {
        width: '100%',
        height: '59vh',
        fontSize: 32,
      },
    },
  })
);

const MobileOrdersTable = React.forwardRef((props: TableProps, ref: any) => {
  const { orders, setOrdersSummary, isMobile } = props;
  const columns = isMobile ? (
    (window.innerHeight > window.innerWidth) ? MobileColumns : LandScapeColumns
    ) : WebColumns;

  const allOrders = useMemo(() => orders, [orders]);
  const allColums = useMemo(() => columns, [columns]);

  const classes = useStyles();
  const history = useHistory();

  const filterTypes = useMemo(
    () => ({
      multiple: (rows: any, id: any, filterValue: string) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
    }),
    []
  );

  const instance = useTable(
    {
      columns: allColums,
      data: allOrders,
      filterTypes,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: allColums.filter((col: any) => col.show === false).map((col: any) => col.accessor),
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  );

  const { flatRows } = instance;

  // return table instance
  useImperativeHandle(ref, () => instance);

  const viewOrderDetails = (
    orderID: string,
    externalOrderID: string,
    shopper: string,
    isCompleted: string,
    customerType: string,
    orderTypeID: string,
    shopName: string,
    startTime: string,
    shoppingCompleted: string,
    deliveryStarted: string,
    deliveryCompleted: string,
    agentID: string,
    driver: string
  ) => {
    history.push(`/orders/${orderID}`, {
      externalOrderID,
      shopper,
      isCompleted,
      customerType,
      orderTypeID,
      shopName,
      startTime,
      shoppingCompleted,
      deliveryStarted,
      deliveryCompleted,
      agentID,
      driver,
    });
  };

  useEffect(() => {
    const ordersSummary = groupOrders(flatRows);
    setOrdersSummary(ordersSummary);
  }, [flatRows, setOrdersSummary]);

  return (
    <Paper className={classes.root}>
      <VirtualizedTable instance={instance} onClickRow={viewOrderDetails} />
    </Paper>
  );
});

export default MobileOrdersTable;
