/**
 * Convert from 'any' type to string (if string, returns string, else returns JSON stringified form).
 *
 * @param {any} value   The value to convert
 *
 * @return {string}     The value as a string / JSON object
 */
 const convertAnyToString = (value: any): string => {
  if (typeof value === 'string' || value instanceof String) {
      return value as string;
  } else {
      return JSON.stringify(value) as string;
  }
};


/**
 * Function to take an array of objects and create an indexed map of it, 1 map for each index in the index array
 *
 * @param array     Array of objects to index
 * @param indexes   The indexes to use for each indexing (1 entry = 1 map returned) (index should be a property / field that exists in object array)
 *
 * @returns {Map<string | undefined, T[]>[]}    The maps created, 1 for each index in indexes and returned in the same order.
 *
 * Returned format of each map (inside array of maps): `{ someKey: matchingObjectsList }`
 *
 * This means that the property name specified in the indexes list can be used as a search by just using the desired search value as the key in `map.get(key)`
 *
 * NOTE: key is a string even if the value of the field indexed is numeric
 */
 export const index = <T, K extends keyof T>(array: T[], indexes: K[]): Map<string | undefined, T[]>[] => {
  // create a map for each index
  const mapsArr: Map<string | undefined, T[]>[] = [];
  // add Map objects to the mapsArr
  for (const i in indexes) {
      mapsArr[i] = new Map<string | undefined, T[]>();
  }
  // loop through the array
  for (const entry of array) {
      // for each index, add to the map at the correct index
      for (const i in indexes) {
          // get the index to use
          const index: K = indexes[i];
          // get the current map to add to
          const currMap: Map<string | undefined, T[]> = mapsArr[i];
          // get the value at that index in the current entry
          const indexValue: string = convertAnyToString(entry[index]);
          // check if need to push or create array
          if (!currMap.has(indexValue)) {
              currMap.set(indexValue, [entry]);
          } else {
              (currMap.get(indexValue) || []).push(entry);
          }
      }
  }
  return mapsArr;
};
