import React from 'react';
import { Dialog, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useModalContext } from 'contexts/ModalContext';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '12px',
  },
  paper: {
    background: 'white',
    padding: theme.spacing(2),
    boxShadow: 'none',
    width: '392px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    margin: theme.spacing(0.5, 0),
    fontSize: '12px',
    fontWeight: 'bold',
  },
  text: {
    margin: theme.spacing(0.5, 0),
    fontSize: '12px',
    fontWeight: 300,
  },
  gif: {
    width: '100px',
    height: '80px',
  },
  button: {
    marginTop: theme.spacing(2),
    borderRadius: '8px',
    textTransform: 'none',
    color: '#FFFFFF',
    background: '#57A946',
    border: 'none',
    padding: '6px 18px',

    '&:hover': {
      color: '#57A946',
      background: '#FFFFFF',
      border: '1px solid #57A946',
    },
  },
}));

interface TransactionStatusModalProps {
  onClose: () => void;
  status: 'success' | 'error';
  orderID: string;
}

const TransactionStatusModal: React.FC<TransactionStatusModalProps> = ({ onClose, status, orderID }) => {
  const classes = useStyles();
  const { transactionStatusModal } = useModalContext();

  const sanitizedOrderID = orderID.replace(/[^a-z0-9-]/gi, '');

  const messages = {
    success: {
      img: '/static/checked.png',
      title: 'Refund successful',
      description: `A refund for Order <strong>${sanitizedOrderID}</strong> has been processed and the amount will be credited to the customer's wallet.`,
    },
    error: {
      img: '/static/FailedTransaction.png',
      title: 'Transaction failed',
      description: "We couldn't verify your request at this time. Please try again later.",
    },
  };

  return (
    <Dialog
      open={transactionStatusModal}
      onClose={onClose}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <Paper className={classes.paper}>
        <img src={messages[status].img} alt={messages[status].title} className={classes.gif} />
        <Typography className={classes.label} variant="h6">
          {messages[status].title}
        </Typography>
        <p className={classes.text} dangerouslySetInnerHTML={{ __html: messages[status].description }} />

        {status === 'success' && (
          <Button onClick={onClose} className={classes.button}>
            Finish
          </Button>
        )}
      </Paper>
    </Dialog>
  );
};

export default TransactionStatusModal;
