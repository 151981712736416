import React, { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme: Theme) => ({
  modalwrapper: {
    padding: theme.spacing(2),
    overflow: 'scroll',
    height: (props: StypeProps) => props?.height || '40%',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface StypeProps {
  height?: string;
}

interface CustomModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => void;
  height?: string;
}

const CustomModal: FunctionComponent<CustomModalProps> = (props) => {
  const { title, children, openModal, closeModal } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      fullWidth={true}
      open={openModal}
      maxWidth={'sm'}
      onClose={closeModal}
      TransitionComponent={Slide}
      classes={{ paper: classes.modalwrapper }}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        {closeModal ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
    </Dialog>
  );
};

export default CustomModal;
