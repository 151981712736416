import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { FunctionComponent } from 'react';
import ActivityHistory from '../components/ActivityHistory';
import Comments from '../components/Comments';
import Transactions from '../components/Transactions';
import VendorsList from '../components/VendorsList';

// import Messaging from './Chat/Messaging/Messaging';
import Receipt from './Receipt';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface OrderListProps {
  orderID: string;
  orderTypeID: string;
  isCompleted: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    minHeight: '75vh',
  },
  appBar: {
    fontWeight: 500,
    backgroundColor: 'white',
    color: '#44444F',
    borderRadius: theme.spacing(2),
  },
}));

const OrderList: FunctionComponent<OrderListProps> = (props) => {
  const classes = useStyles();
  const { orderID, orderTypeID, isCompleted } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div>
        <AppBar className={classes.appBar} position="static">
          <Tabs
            indicatorColor="primary"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="order list tabs"
          >
            {/* <Tab label="Shopper chat" {...a11yProps(0)} /> */}
            {isCompleted ? <Tab label="Receipt" {...a11yProps(0)} /> : <Tab label="Shopping list" {...a11yProps(0)} />}
            <Tab label="Transactions" {...a11yProps(2)} />
            <Tab label="Comments" {...a11yProps(3)} />
            <Tab label="Activity History" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        {/* <TabPanel value={value} index={0}>
        {isCompleted ? (
          <Receipt orderTypeID={orderTypeID} isCompleted={isCompleted} orderID={orderID} />
        ) : (
          <Messaging />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isCompleted ? <Messaging /> : <VendorsList orderID={orderID} />}
      </TabPanel> */}
        <TabPanel value={value} index={0}>
          {isCompleted ? (
            <Receipt orderTypeID={orderTypeID} isCompleted={isCompleted} orderID={orderID} />
          ) : (
            <VendorsList orderID={orderID} />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Transactions orderID={orderID} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Comments orderID={orderID} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ActivityHistory orderID={orderID} />
        </TabPanel>
      </div>
    </div>
  );
};

export default OrderList;
