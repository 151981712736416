import React, { FunctionComponent, useState } from 'react';

import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    border: '1px solid #C4C4C4',
    borderRadius: theme.spacing(1),
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '70%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    height: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  inputLabel: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
}));

interface GlobalFilterProps {
  tableInstance?: any;
  setOrderID?: any;
}
const GlobalFilter: FunctionComponent<GlobalFilterProps> = (props) => {
  const classes = useStyles();
  const { tableInstance, setOrderID } = props;
  const [orderID, setOrderIDState] = useState<string | null>(null);
  const [isSearchingOrder, setIsSearchingOrder] = useState<boolean>(false);

  return (
    <div className={classes.search}>
      <InputBase
        placeholder="Search Orders…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        endAdornment={
          <>
            <SearchIcon className={classes.searchIcon} color="primary" />
            <Button
              variant="text"
              color="secondary"
              size="large"
              disabled={!orderID}
              onClick={(e) => {
                setIsSearchingOrder(true);
                setOrderID(orderID);
              }}
            >
              <span className={classes.inputLabel}>Search</span>
            </Button>
          </>
        }
        onChange={(e) => {
          tableInstance.current?.setGlobalFilter(e.target.value);

          if (e.target.value.match(/^[1-9][0-9]{5,}[^0-9-]*(-[0-9]+)?$/)) {
            // the target value is a potential orderID
            setOrderIDState(e.target.value);
          } else {
            if (orderID) {
              setOrderIDState(null);
              if (isSearchingOrder) {
                // only reset if searching order (to avoid too many refreshes)
                setIsSearchingOrder(false); // mark as no longer searching an order
                setOrderID(null); // set the orderID to search for (null = don't search for order)
              }
            }
          }
        }}
      />
    </div>
  );
};

export default GlobalFilter;
