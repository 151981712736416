import { useEffect, useState } from 'react';
import * as store from 'store';

export function usePermissions() {
  const [permissions, setPermissions] = useState<string[]>([]);

  const updatePermissions = (userPermissions: string[]) => {
    const userRoles = userPermissions || store.get('permissions') || [];
    if (userRoles?.length) setPermissions(userRoles);
    return userRoles;
  };

  const isPermitted = (requiredPermissions: string[], acessType: 'any' | 'all' = 'all', userPermissions?: string[]) => {
    let userRoles = permissions;
    if (!permissions?.length && userPermissions?.length) {
      updatePermissions(userPermissions);
      userRoles = userPermissions;
    }
    switch (acessType) {
      case 'any':
        return requiredPermissions.some((p) => userRoles.includes(p));
      case 'all':
        return requiredPermissions.every((p) => userRoles.includes(p));
      default:
        return false;
    }
  };

  const hasPermissions = () => {
    return permissions.length > 0;
  };

  useEffect(() => {
    setPermissions(store.get('permissions') || []);
  }, []);

  return {
    permissions,
    hasPermissions,
    updatePermissions,
    isPermitted,
  };
}
