import React, { useEffect, useState, useCallback } from 'react';
import CustomModal from './CustomModal';
import ReceiptsView from './ReceiptsView';

const Receipts = ({
  orderId,
  open,
  closeReceipts,
  accessToken,
}: {
  orderId: string;
  open: boolean;
  closeReceipts: () => void;
  accessToken: string;
}) => {
  const [receipts, setReceipts] = useState([]);

  const fetchReceipts = useCallback(async () => {
    const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/receipts`;
    const response = await fetch(url, {
      headers: { Authorization: accessToken },
    })
      .then((resp) => {
        return resp.json();
      })
      .catch((error) => console.log(error));

    if (response?.details) {
      setReceipts(response.details);
    } else {
      setReceipts([]);
    }
  }, [accessToken, orderId]);

  useEffect(() => {
    fetchReceipts();
  }, [fetchReceipts]);

  return (
    <CustomModal title="Receipts" openModal={open} closeModal={closeReceipts}>
      {receipts.map((receipt, index) => (
        <ReceiptsView receipt={receipt} key={index} />
      ))}
    </CustomModal>
  );
};

export default Receipts;
