import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FunctionComponent } from 'react';
import MallFilter from '../components/MallFilter';
import { useWindowParams } from '../hooks/useWindowParams';
import { BreakPoints } from '../theme/breakPoints';
import DateFilter from './DateFilter';
import StatusSelect from './StatusSelect';
import VendorFilter from './VendorFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: '10px',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(5, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '0px',
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridTemplateRows: 'repeat(5, 1fr)',
    },
  },

  noPadding: {
    padding: 0,
  },
}));

const FilterResults: FunctionComponent = () => {
  const { width } = useWindowParams();
  const isMobile = width <= BreakPoints.sm;
  return (
    <FilterWrapper mobile={isMobile}>
      <VendorFilter mobile={isMobile} />
      <MallFilter mobile={isMobile} />
      <React.Fragment>
        <DateFilter mobile={isMobile} />
        <StatusSelect mobile={isMobile} />
      </React.Fragment>
    </FilterWrapper>
  );
};

function FilterWrapper({ mobile, children }: { mobile: boolean; children: React.ReactNode }) {
  const classes = useStyles();

  if (!mobile)
    return (
      <>
        <Typography>Filter results</Typography>
        <div className={classes.root}>{children}</div>
      </>
    );

  return (
    <Accordion square={true} classes={{ root: classes.noPadding }}>
      <AccordionSummary className={classes.noPadding} expandIcon={<ExpandMoreIcon />}>
        <Typography>Filter results</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.noPadding}>
        <div className={classes.root}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
}

export default FilterResults;
