import React from 'react';
import PieChart from '../../components/PieChart';
import { useWindowParams } from '../../hooks/useWindowParams';
import { useChartStyles } from './DeliveryStatus';

interface ChartProps {
  data: {
    percItemsFound: number;
    percItemsSubstituted: number;
    percItemsPartial: number;
    percItemsNotFound: number;
  };
}

function ProductSupplyChart(props: ChartProps) {
  const classes = useChartStyles();
  const { isMobile } = useWindowParams();
  const {
    percItemsFound,
    percItemsSubstituted,
    percItemsPartial,
    percItemsNotFound,
  } = props?.data ?? {};
  return (
    <div className={classes.root}>
      <div className={classes.title}>% Product Supply</div>
      <div className={classes.container}>
        <div className={classes.chartContainer}>
          <PieChart
            labels={['% Found', '% Partial Supply', '% Substituted', '% Not Found']}
            data={[percItemsFound, percItemsPartial, percItemsSubstituted, percItemsNotFound]}
            colors={['#3182EB', '#00C853', '#FF7D1B', '#F05A69']}
            legend={{ position: isMobile ? 'top' : 'right' }}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductSupplyChart
