import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { onBackgroundMessage, isSupported } from "firebase/messaging/sw";
import * as store from 'store';
import { foregroundNotification } from "./utils/Notifications";

// Initialize Firebase 
const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const messaging = getMessaging(firebaseApp);

getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
  if (currentToken) {
    // store firebaseToken to use on online
    store.set('firebaseToken', currentToken);

    onMessage(messaging, (payload) => {
      const orderID = payload?.data?.orderID;
      const notificationTitle = payload?.notification?.title ?? payload?.data?.title;
      const message = payload?.notification?.body;
      const icon = payload?.notification?.icon;
      
      foregroundNotification(orderID, notificationTitle, message, icon)

      // store notification to the notifications list
      const date = new Date();
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();
      const expDate = `${mm}-${dd}-${yyyy}`;

      const getNotifications = store.get('notifications');
      const currentList = getNotifications ? JSON.parse(getNotifications) : [];

      const notificationsArray = [];

      // add new item so it first in the array
      notificationsArray.push({
        orderID: orderID,
        title: notificationTitle,
        message: payload?.notification?.body,
        date: expDate,
        isRead: false,
        icon,
      })

      currentList.forEach((item) => {
        // clean up item order older than today
        if (item.date >= expDate) {
          notificationsArray.push(item);
        }
      });
      
      // save you list of the notification
      store.set('notifications', JSON.stringify(notificationsArray));
    });
    
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    requestNotificationPermission();
    // ...
  }
}).catch((err) => {
  console.error('An error occurred while retrieving token. ', err);
  // ...
});

const requestNotificationPermission = () => {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  });
}