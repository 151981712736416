import React, { useState, FC } from 'react';
import * as store from 'store';
import { Product, nodeProduct, slsProduct } from 'types';
import { searchAlgoliaIndex, similarProducts } from '../api';
import SelectedSubstitute from './SelectedSubstitute';
import { useModalContext } from 'contexts/ModalContext';
import { makeStyles } from '@material-ui/core/styles';

const placeholderImage = 'https://via.placeholder.com/50';

interface Props {
  productID?: string;
  onSubstituteSelect: (selectedProduct: Product | null) => void;
}

// Utility type guards
const isProductType1 = (product: Product): product is nodeProduct => 'imageURL' in product;
const isProductType2 = (product: Product): product is slsProduct => 'ImageURL' in product;

// Get correct image URL based on product type
const getProductImageURL = (product: Product): string => {
  if (isProductType1(product)) return product.imageURL;
  if (isProductType2(product)) return product.ImageURL;
  return placeholderImage;
};

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    padding: '16px 8px',
    boxSizing: 'border-box',
    border: '1px solid black',
    borderRadius: '8px',
    marginTop: '12px',
  },
  dropdown: {
    border: '1px solid #ccc',
    padding: 0,
    margin: 0,
    listStyleType: 'none',
    maxHeight: '300px',
    overflowY: 'auto',
  },
  dropdownItem: {
    padding: '8px',
    cursor: 'pointer',
    borderBottom: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  productImage: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
  },
  productInfo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedContainer: {
    marginTop: '16px',
    border: '1px solid black',
    borderRadius: '8px',
    padding: '8px 6px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    width: '98%',
    height: 'auto',
  },
  noProductsMessage: {
    textAlign: 'center',
    padding: '8px',
  },
}));

const SubstituteSearchInput: FC<Props> = ({ productID, onSubstituteSelect }) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedSubstitute, setSelectedSubstitute] = useState<Product | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const { selectedProduct } = useModalContext();

  const accessToken = store.get('spotlightAccessToken');

  const fetchSimilarProducts = async () => {
    if (productID) {
      try {
        const response = await similarProducts({ accessToken, productID });
        setProducts(response.data.details);
      } catch (error) {
        console.error('Error fetching similar products:', error);
      }
    }
  };

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    setShowDropdown(true);

    if (searchQuery.length > 3) {
      try {
        const result = await searchAlgoliaIndex(query.trim(), {
          facetFilters: [`vendor.name:${selectedProduct.vendor}`],
        });
        const filteredProducts = result.hits.filter((h) => !!h.pricing);
        setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
      }
    } else {
      fetchSimilarProducts();
    }
  };

  const handleFocus = () => {
    setShowDropdown(true);
    if (query.length <= 2 && productID) {
      fetchSimilarProducts();
    }
  };

  const handleItemClick = (product: Product) => {
    setQuery(product.name);
    setSelectedSubstitute(product);
    setShowDropdown(false);
    onSubstituteSelect(product);
  };

  return (
    <div>
      {selectedSubstitute ? (
        <div
          className={classes.selectedContainer}
          onClick={() => {
            setSelectedSubstitute(null);
            setQuery('');
          }}
        >
          <SelectedSubstitute product={selectedSubstitute} />
        </div>
      ) : (
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          onFocus={handleFocus}
          placeholder="Search for substitute products..."
          className={classes.input}
        />
      )}

      {showDropdown && products.length > 0 ? (
        <ul className={classes.dropdown}>
          {products.map((product) => (
            <li key={product.productID} onClick={() => handleItemClick(product)} className={classes.dropdownItem}>
              <img src={getProductImageURL(product)} alt={product.name} className={classes.productImage} />
              <div className={classes.productInfo}>
                <span>{product.name}</span>
                <strong>
                  R
                  {product.price
                    ? product.price.toFixed(2)
                    : product.pricing && product.pricing[0]?.originalPrice
                    ? product.pricing[0]?.originalPrice.toFixed(2)
                    : 'N/A'}
                </strong>
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default SubstituteSearchInput;
