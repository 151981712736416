import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {ICard} from '../types'

const useStyles = makeStyles((theme) => ({
  root: (props:ICard) => ({
    minWidth: 200,
    background: props.background,
    color: 'white',
  }),
  title: {
    fontSize: 18,
    padding: theme.spacing(1),
  },
  total: {
    textAlign: 'center',
    fontSize: '48px',
    fontweight: '500',
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  trendingUpIcon: {
    float: 'right',
  },
}));

const OrderCard = ({ cardData, amount }: {cardData: ICard, amount: number}) => {
  const classes = useStyles(cardData);
  return (
    <Grid item xs={12} sm={3}>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} gutterBottom>
            {cardData.title}
            <TrendingUpIcon className={classes.trendingUpIcon} />
          </Typography>
          <Typography className={classes.total}>{amount}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderCard;
