import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { useModalContext } from 'contexts/ModalContext';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import { orderProductStatus } from 'types/enums';

const itemKey = (index: number, data: any) => data.items[index].original.id;

export const generateColumns = (width: number = 0, columns: any[]) => {
  const totalColumnWidth = columns.reduce((acc: any, col: any) => acc + col.minWidth, 0);
  if (!width || width === 0) width = totalColumnWidth;

  const viewPort = width - getScrollbarWidth();

  const templateColumns = columns.map((column) => {
    const colMinWidth = column?.minWidth || 100;
    const colWidth = parseFloat(`${colMinWidth * (viewPort / totalColumnWidth)}`);
    return `${colWidth.toFixed(2)}px`;
  });

  return templateColumns.join(' ');
};

function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer?.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

const useTableStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
  },
  tbody: {
    width: '100%',
    height: 'calc(69vh - 82px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(69vh - 62px)',
    },
  },
  header: {
    height: '80px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: '#fafafa',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
  },
  row: {
    border: 'none',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    minHeight: '90px',
    padding: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      minHeight: '50px',
    },
  },
  cell: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  grid: {
    display: 'grid',
    alignItems: 'center',
    verticalAlign: 'middle',
    gridTemplateRows: 'auto',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    gridTemplateColumns: ({ template }: any) => `${template}`,
  },
  tooltip: {
    fontSize: 14,
  },
  boldText: {
    fontWeight: 500,
  },
}));

const Row = ({ index, style, data, prepareRow, onClickRow, rowSize }: any) => {
  const row = data.items[index];
  const { classes, editedProductIds = [] } = data;
  const isEdited = Array.isArray(editedProductIds) && editedProductIds.includes(row.original.productID); // Check if the product is edited

  prepareRow(row);

  const getCellHighlightingRules = (cell: any) => {
    const rule = row.original.magic?.find((x: any) => x.column === cell.column.id);
    return {
      style: { backgroundColor: rule?.color ?? 'transparent', height: '100%' },
      title: rule?.title,
    };
  };

  const imageURL = row.original.imageURL;
  const productName = row.original.name;

  const getStatusText = (status: orderProductStatus, isEdited: boolean) => {
    switch (status) {
      case orderProductStatus.COMPLETED:
        return <span style={{ color: isEdited ? '#4caf50' : '' }}>Product Found</span>;
      case orderProductStatus.PARTIAL:
        return <span style={{ color: isEdited ? '#1876D1' : '' }}>Partially Found</span>;
      case orderProductStatus.CUSTOMER_SUB:
      case orderProductStatus.SHOPPER_SUB:
        return <span style={{ color: isEdited ? '#F47C01' : '' }}>Product Substituted</span>;
      case orderProductStatus.NOT_DONE:
      case orderProductStatus.WAITING:
        return <span style={{ color: isEdited ? '#FFA500' : '' }}>In Progress</span>;
      case orderProductStatus.REFUND:
        return <span style={{ color: isEdited ? 'red' : '' }}>Refunded</span>;
      default:
        return <span style={{ color: isEdited ? 'black' : '' }}>Pending</span>;
    }
  };

  return (
    <div style={style}>
      <TableRow
        {...row.getRowProps()}
        style={{ height: rowSize }}
        className={`${classes.row} ${classes.grid}`}
        component="div"
        tabIndex={-1}
        hover
      >
        <TableCell component="div" variant="body" className={`${classes.row} ${classes.cell}`}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={imageURL}
              alt={productName}
              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
            />
            <span className={isEdited ? classes.boldText : ''}>{productName}</span>
          </div>
        </TableCell>

        {row.cells.map((cell: any, index: number) => {
          const rule = getCellHighlightingRules(cell);
          let cellValue;

          if (cell.column.id === 'status') {
            cellValue = getStatusText(row.original.status as orderProductStatus, isEdited);
          } else if (cell.column.id === 'price') {
            cellValue = `R${cell.value}`;
          } else {
            cellValue = cell.render('Cell');
          }

          if (cell.column.id === 'name') {
            return null;
          }

          return (
            <Tooltip
              key={index}
              title={rule.title || ''}
              disableHoverListener={!rule.title}
              className={classes.cell}
              classes={{ tooltip: classes.tooltip }}
            >
              <TableCell
                {...cell.getCellProps()}
                className={isEdited ? `${classes.row} ${classes.boldText}` : `${classes.row} ${classes.cell}`}
                style={rule.style}
                component="div"
                variant="body"
              >
                {cell.render('Cell', { value: cellValue })}
              </TableCell>
            </Tooltip>
          );
        })}
      </TableRow>
    </div>
  );
};

const TableHeader = (props: any) => {
  const { headerGroups, classes } = props;
  return (
    <TableHead component="div">
      {headerGroups.map((group: any) => (
        <TableRow {...group.getHeaderGroupProps()} className={`${classes.grid} ${classes.header}`} component="div">
          {group.headers.map((column: any) => (
            <TableCell
              {...column.getHeaderProps(column.getSortByToggleProps())}
              className={classes.row}
              component="div"
              variant="head"
              scope="col"
            >
              {column.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

const VirtualizedProductsTable = (props: any) => {
  const { instance, onClickRow } = props;
  const { editedProducts } = useModalContext();

  const [templateColumns, setRowColumns] = useState('');
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { rows, getTableProps, getTableBodyProps, headerGroups, prepareRow } = instance;

  const columns = useMemo(() => {
    return (
      headerGroups[0]?.headers.map((col: any) => ({
        id: col.id,
        minWidth: 100,
      })) || []
    );
  }, [headerGroups]);

  const classes = useTableStyles({ template: templateColumns });
  const data = useMemo(
    () => ({ classes, items: rows, editedProductIds: editedProducts }),
    [classes, rows, editedProducts]
  );

  useEffect(() => {
    const rect = tableContainerRef.current?.getBoundingClientRect();
    const template = generateColumns(rect?.width || 0, columns);
    if (template !== templateColumns) {
      setRowColumns(template);
    }
  }, [columns, templateColumns, editedProducts]);

  return (
    <TableContainer className={classes.container} ref={tableContainerRef}>
      <Table {...getTableProps()} component="div" stickyHeader aria-label="sticky table">
        <TableHeader headerGroups={headerGroups} classes={classes} template={templateColumns} />
        <TableBody component="div" className={classes.tbody} {...getTableBodyProps()}>
          <AutoSizer>
            {({ height, width }: any) => {
              if (!height || height === 0) height = 560;
              if (!width || width === 0) width = '100%';
              return (
                <List
                  width={width}
                  height={height}
                  className={'react-window-list'}
                  itemCount={rows.length}
                  itemSize={90}
                  itemKey={itemKey}
                  overscanCount={10}
                  itemData={data}
                >
                  {(rowProps) => {
                    const allRowProps = { ...rowProps, data, prepareRow, onClickRow };
                    return <Row {...allRowProps} rowSize={50} />;
                  }}
                </List>
              );
            }}
          </AutoSizer>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VirtualizedProductsTable;
