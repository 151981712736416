import React, { FunctionComponent, ChangeEvent } from 'react';
import * as store from 'store';
import { useQuery } from 'react-query';
import { getStores } from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { useOrdersContext } from '../../contexts/OrdersContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '90%',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(768)]: {
      minHeight: '20px',
      minWidth: '90%',
    },
  },
  label: {
    backgroundColor: 'white',
    [theme.breakpoints.down(768)]: {
      fontSize: 9,
      top: -4,
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      fontSize: 9,
      top: -4,
    },
  },
  select: {
    minWidth: '280px',
    fontSize: '15px',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
      height: '40px',
      minWidth: '100%',
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      fontSize: '12px',
      height: '40px',
      minWidth: '100%',
    },
  },
  menuPaper: {
    maxHeight: 500,
    fontSize: '18px',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
      margin: theme.spacing(0),
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      fontSize: '12px',
      margin: theme.spacing(0),
    },
  },
}));

interface Store {
  name: string;
}

const MobileStatusSelect: FunctionComponent = () => {
  const accessToken = store.get('spotlightAccessToken');
  const { selectedStatus, setSelectedStatus, selectedStores, setSelectedStores } = useOrdersContext();
  const classes = useStyles();

  let stores = [];
  const { data } = useQuery<any, Error>(['stores', accessToken], () => getStores({ accessToken }), {
    enabled: !!accessToken,
  });

  if (data?.data) {
    const allStores = data?.data.details;
    const currentStores = store.get('currentStores');

    if (currentStores !== undefined) {
      let filter = allStores.filter((value: any) => currentStores.includes(value.name));
      stores = filter;
    } else {
      stores = allStores;
    }
  }
  stores.sort((a: Store, b: Store) => (a.name > b.name ? 1 : -1));

  const handleStoreChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setSelectedStores([...(event.target.value as string[])]);
  };

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>): void => {
    let values = event.target.value as number[];
    if (values.includes(4)) values = values.filter((x) => x === 4);
    return setSelectedStatus([...values]);
  };

  const statuses = [
    { name: 'In Progress', value: 3 },
    { name: 'Not Started', value: 2 },
    { name: 'Running late', value: 4 },
    { name: 'Completed', value: 1 },
    { name: 'Cancelled', value: 5 },
  ];

  const statusNames = (selected: unknown) => {
    const selectedNames = (selected as string[]).map((sel) => {
      const status = statuses.find((stat) => stat.value === Number(sel));
      return status?.name;
    });
    return selectedNames.join(', ');
  };

  return (
    <>
      <Grid item sm={4} xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="status-label" className={classes.label}>
            Choose Status
          </InputLabel>
          <Select
            labelId="statusselect-label"
            id="status-select"
            multiple
            value={selectedStatus}
            onChange={handleStatusChange}
            input={<OutlinedInput />}
            renderValue={(selected) => statusNames(selected)}
            MenuProps={{
              classes: { paper: classes.menuPaper },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            className={classes.select}
          >
            {statuses.map((stat) => {
              return (
                <MenuItem key={stat.name} value={stat.value}>
                  <Checkbox checked={selectedStatus.indexOf(stat.value) > -1} />
                  <ListItemText primary={stat.name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={4} xs={12}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="mallfilter-label" className={classes.label}>
            Choose Mall
          </InputLabel>
          <Select
            labelId="store-select-label"
            id="s-select"
            value={selectedStores}
            multiple
            input={<OutlinedInput />}
            onChange={handleStoreChange}
            renderValue={(selectedStores) => (selectedStores as string[]).join(', ')}
            MenuProps={{
              classes: { paper: classes.menuPaper },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            className={classes.select}
          >
            {stores.map((store: Store) => (
              <MenuItem key={store.name} value={store.name}>
                <Checkbox checked={selectedStores.indexOf(store.name) > -1} />
                <ListItemText primary={store.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default MobileStatusSelect;
