import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AnalyticsOrderSummary } from '../../types';
import { useAnalyticsStyles } from './Styles';

export interface FilterProps {
  endDate: string;
  startDate: string;
  setEndDate: (date: string) => void;
  setstartDate: (date: string) => void;
  setSelectedIndex: (date: number) => void;
  selectedIndex: number;
  data?: AnalyticsOrderSummary;
};

export default function AnalyticsFilters(props: FilterProps) {
  const classes = useAnalyticsStyles();
  const { data } = props;
  return (
    <div className={classes.appBar}>
      <div className={`filters ${classes.filters} ${classes.toolBar}`}>

        <Typography className={classes.filterHeading} variant="h6" noWrap>
          {data?.storeID && `${data?.storeName} (${data?.storeLocation})`}
        </Typography>

        <div className={classes.filterSection}>
          <DateFilter
            value={props.startDate}
            onChange={props.setstartDate}
            title="From"
          />
          <DateFilter
            value={props.endDate}
            onChange={props.setEndDate}
            title="To"
          />
        </div>

      </div>
    </div>
  )
}

function DateFilter(props: any) {
  const classes = useAnalyticsStyles();
  const [date, setDate] = useState(props.value);
  const { title = '', onChange = () => { } } = props;
  const handleChange = (event: any) => {
    event.preventDefault();
    setDate(event.target.value);
    onChange(event.target.value);
  }
  return (
    <div className={classes.dateFilter}>
      <div className={classes.filterName}>{title}</div>
      <input className={classes.filterInput} type='date' onChange={handleChange} value={date} />
    </div>
  )
}
