import 'chart.js/auto';
import React from "react";
import { Doughnut } from "react-chartjs-2";

interface DatasetType {
  data: number[];
  labels: string[];
  colors: string[];
  legend?: {
    position: "left" | "top" | "right" | "bottom" ;
  }
}

function PieChart(props: DatasetType) {
  const { labels = [], data = [], colors = [] } = props;
  if (!labels.length || !data.length || !colors.length) return null;
  if (labels.length !== data.length || labels.length !== colors.length) return null;
  return (
    <Doughnut
      options={{
        plugins: {
          legend: {
            position: props?.legend?.position ?? 'right',
          }
        }
      }}
      data={{
        labels,
        datasets: [{ data, backgroundColor: colors }]
      }}
    />
  );
}

export default PieChart;
