import React, { Fragment, FunctionComponent, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useQueryClient } from 'react-query';
import * as store from 'store';
import { createToastMessage } from './toast';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery, useMutation } from 'react-query';
import { changeStore, getStores } from '../api';
import { Order } from '../types';
import CustomModal from './CustomModal';
import EmptyState from '../components/empty';

interface Store {
  name: string;
}
interface ChangeOrderProps {
  open: boolean;
  closeChangeStore: () => void;
  orderID: string;
  refetchActivityHistory: () => void;
}
type Result = { success: true; value: Order } | { success: false; error: Error };

const ChangeStore: FunctionComponent<ChangeOrderProps> = (props) => {
  const { open, closeChangeStore, orderID, refetchActivityHistory } = props;
  const [clickedStore, setStore] = useState('');

  const accessToken = store.get('spotlightAccessToken');
  let stores = [];
  const changeStoreMutation = useMutation(changeStore);
  const { data, isLoading } = useQuery<any, Error>(['stores', accessToken], () => getStores({ accessToken }), {
    enabled: !!accessToken,
  });
  if (data?.data) {
    stores = data?.data.details;
  }

  const queryClient = useQueryClient();
  const cachedOrders = queryClient.getQueryData('orders') as Order[];

  function getOrderFromCache(): Result {
    if (cachedOrders) {
      const foundOrder = cachedOrders.find((o: Order) => o.orderID === orderID);
      return foundOrder ? { success: true, value: foundOrder } : { success: false, error: Error('No order found') };
    } else {
      return {  
        success: false,
        error: Error('orders cache is empty'),
      };
    }
  }

  const handleClick = (storeName: string) => {
    setStore(storeName);
    if (accessToken) {
      const order = getOrderFromCache();
      changeStoreMutation.mutate(
        { orderID, storeName, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            if (order.success) {
              order.value.shopName = storeName;
              queryClient.setQueryData(['orders', { id: order.value.orderID }], order.value);
            }
            closeChangeStore();
            refetchActivityHistory();
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  return (
    <Fragment>
      <CustomModal title="Change store" openModal={open} closeModal={closeChangeStore}>
        {isLoading && <EmptyState loading title="Fetching Stores..." />}

        <List component="nav">
          {stores.map(({ name }: Store) => (
            <Fragment key={name}>
              <ListItem button onClick={() => handleClick(name)}>
                <ListItemText primary={name} />
                {changeStoreMutation.isLoading && clickedStore === name && (
                  <CircularProgress color="secondary" size={15} style={{ marginLeft: 3 }} />
                )}
              </ListItem>

              <Divider />
            </Fragment>
          ))}
        </List>
      </CustomModal>
    </Fragment>
  );
};

export default ChangeStore;
