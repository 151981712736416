import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
export function createToastMessage({ message, error }) {
  const position = toast.POSITION.TOP_RIGHT;

  if (error) {
    const errorMessage = message.response.data.message ? message.response.data.message : message.message;
    toast.error(errorMessage, { position: position, autoClose: 4000 });
  } else {
    toast.success(message, { position: position, autoClose: 4000 });
  }
}
