import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../images/spotlight.png';
import AlertNotification from './supervisorAlerts/AlertNotification';

toast.configure();

/**
 * @param message {string} - Message to display
 * @param type {'E' | 'S' |'I'} - Error, Success or Info message
 * @param time - Message timeout
 */

export function createToastMessage(message, type, time) {
  let position;

  if (type === 'E') {
    position = toast.POSITION.TOP_RIGHT;

    toast.error(message, { position: position, autoClose: 4000 });
  } else if (type === 'S') {
    position = toast.POSITION.TOP_RIGHT;

    if (time) {
      toast.success(message, { position: position, autoClose: time });
    } else {
      toast.success(message, { position: position, autoClose: 3000 });
    }
  } else if (type === 'I') {
    position = toast.POSITION.BOTTOM_RIGHT;
    toast.info(message, { position: position, autoClose: 2000 });
  }
}

/**
 *
 * @param message {string} - Message to display
 * @param title {string} - Notification Title (Defaults to OneCart)
 *
 */

export async function createBrowserNotification(message, title, orderID) {
  let granted = Notification.permission === 'granted';

  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    createToastMessage('This browser does not support desktop notification', 'E');
  }

  //show a toast requesting permission if not granted

  if (Notification.permission !== 'granted') {
    createToastMessage(`You have a new Message from ${title}`, 'S');

    createToastMessage('Kindly grant browser notification permission', 'E');
  }

  //await the user to grant permission if not blocked.

  if (Notification.permission !== 'denied') {
    let permission = await Notification.requestPermission();

    granted = permission === 'granted' ? true : false;
  }

  if (!granted) return;

  // create a new notification

  const notification = new Notification(title || 'OneCart', {
    body: `#:${orderID}\n${message}`,
    icon: logo,
    vibrate: true,
  });

  // close the notification after 10 seconds

  setTimeout(() => {
    notification.close();
  }, 10 * 1000);

  // navigate to a URL when clicked

  notification.addEventListener('click', () => {
    //TODO: write a method to push to right order and right channel
  });
}


//keep for later use
export async function foregroundNotification(orderID, title, message, icon) {

  const position = toast.POSITION.TOP_RIGHT;
  const options = {
    position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  }
  toast(<AlertNotification orderID={orderID} title={title} message={message} icon={icon}/>, options);

  // const options = {
  //   onOpen: props => console.log(props.foo),
  //   onClose: props => console.log(props.foo),
  //   autoClose: 6000,
  //   closeButton: FontAwesomeCloseButton,
  //   type: toast.TYPE.INFO,
  //   hideProgressBar: false,
  //   position: toast.POSITION.TOP_LEFT,
  //   pauseOnHover: true,
  //   transition: MyCustomTransition,
  //   progress: 0.2
  //   // and so on ...
  // };
}