import React, { FunctionComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';

interface IconButtonProps {
  className?: string,
  edge?: 'start' | 'end' | false;
  handleClick?: React.ReactEventHandler<{}>;
}

const CustomIconButton: FunctionComponent<IconButtonProps> = (props) => {
  return (
    <IconButton
      edge={props.edge}
      className={props.className}
      onClick={props.handleClick}
    >
      {props.children}
    </IconButton>
  );
};

export default CustomIconButton;
