import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useOrdersContext } from '../contexts/OrdersContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
    maxWidth: 'none !important',
  },
  menuPaper: {
    maxHeight: 200,
  },
  values: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

interface VendorFilterProps {
  mobile?: boolean;
}

const VendorFilter: FunctionComponent<VendorFilterProps> = (props) => {
  const classes = useStyles();
  const margin = !props.mobile ? 'normal' : 'dense';

  const { vendor, setVendor } = useOrdersContext();

  const handleVendorChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setVendor(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl} margin={margin} fullWidth>
      <InputLabel id="mall-label">Vendor</InputLabel>
      <Select
        label="Choose mall"
        labelId="mall-select-label"
        id="mall-select"
        value={vendor}
        className={classes.values}
        onChange={handleVendorChange}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="All">All</MenuItem>
        <MenuItem value="Onecart">Onecart</MenuItem>
        <MenuItem value="Woolworths">Woolworths</MenuItem>
        <MenuItem value="Ava">Avo</MenuItem>
      </Select>
    </FormControl>
  );
};

export default VendorFilter;
