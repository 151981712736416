import { AxiosResponse } from 'axios';
import algolia from 'algoliasearch/lite';
import * as store from 'store';
import evaluateHighlightingRules, { getIndexedRagRules } from '../theme/orderStatus';
import { AnalyticsDeliverySummary, AnalyticsOrderSummary, Product } from '../types';
import {
  axiosInstance,
  getUrlWithQueryParams,
  hasPickupOrDropOffException,
  isCompletedLate,
  isRunningLate,
} from '../utils/utils';

interface ServerlessBaseResponse<T> {
  details: T;
  message?: string;
}
interface GetOrdersProps {
  startDate: string;
  endDate: string;
  orderID?: string | null;
  accessToken: string | null;
  perPage?: number;
  page?: number;
}

interface Token {
  accessToken: string | null;
}

interface Order extends Token {
  orderID: string;
}

interface Reset extends Order {
  resetShopper: number;
  reason: string;
  hasRefundProof: string;
  driverHasOrder: string;
  orderPictureProof: string;
}

interface Reschedule extends Order {
  deliveryDate: string;
  deliveryFrom: string;
}
interface ChangeStore extends Order {
  storeName: string;
}
interface Comment extends Order {
  comment: string;
}
interface GetReceiptProps extends Order {
  email: string;
}
interface Action extends Token {
  actionID: number;
}

interface QueryProps {
  query: string;
}

interface SearchSuggestionsResponse {
  hits: {
    query: string;
  };
}

interface AlgoliaProduct {
  objectID: string;
  name: string;
  barcode: string;
  imageURL?: string;
  description?: string;
  vendor: {
    id: number;
    name: string;
    imageURL?: string;
  };
  categories?: {
    lvl0?: string[];
    lvl1?: string[];
  };
  stock?: {
    storeId?: number;
    quantity: number;
  }[];
  pricing?: AlgoliaPricing[];
  _geoloc?: {
    lat: number;
    lng: number;
  }[];
}

type AlgoliaPricing = {
  storeId?: number | string;
  originalPrice: number;
  percentageDiscount?: string;
  amountDiscount?: string;
  discountStartDate?: string;
  discountEndDate?: string;
};

interface SearchResponse<T> {
  hits: T[];
  nbHits: number;
  page: number;
  nbPages: number;
  hitsPerPage: number;
  processingTimeMS: number;
}

interface ProductFound extends Token {
  selectedProduct: Product;
  quantity?: number;
  forceBarcodes: boolean;
  barcodes: string[];
  subProductID?: string;
  subQuantity?: number;
  subBarcode?: string;
  orderID: string;
}
interface SubstituteProduct extends Token {
  orderID: string;
  selectedProduct: Product;
  shopperSuggested: boolean;
  subProductID: string;
  subQtyFound: number;
  subBarcode?: string;
  forceBarcodes: boolean;
  barcodes: string[];
  qtyFound: number;
}
interface PostOrder extends Token {
  orderID: string;
}
interface ProductNotFound extends Token {
  selectedProduct: Product;
  orderID: string;
}
interface BarCode extends Token {
  barcode: string;
}

declare interface BarcodeDecode {
  originalBarcode: string;
  gs1Databar?: {
    gs1Prefix: {
      ai: string;
      value: string;
      date?: string;
    };
    gs1Parts: {
      ai: string;
      value: string;
      date?: string;
    }[];
    gs1Other: {
      ai: string;
      value: string;
      date?: string;
    }[];
    data: {
      prodDate?: string;
      dueDate?: string;
      packDate?: string;
      bestBefore?: string;
      sellBy?: string;
      expiry?: string;
    };
  };
  barcode: {
    barcode: string;
    sku: string;
    valid: boolean;
    // weighted is returned if it's a weighted item (RCN)
    weighted?: {
      prefix: string;
      itemID: string;
      priceChecksum: string;
      price: number;
      checksum: string;
      rcn: string;
    };
    // issn is returned if it's an EAN-13 ISSN
    issn?: {
      prefix: string;
      issn: string;
      variant: string;
      checksum: string;
      gtin: string;
    };
    // parts is returned if it's a standard GTIN / other
    parts?: {
      itemID: string;
      checksum: string;
      gtin: string;
    };
  };
}

const appID = process.env.REACT_APP_ALGOLIA_APP_ID || '';
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY || '';

const algoliaSearch = algolia(appID, apiKey);

export async function getOrders(props: GetOrdersProps) {
  const { startDate, endDate, orderID, accessToken, perPage, page } = props;

  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + '/v1/spotlight/orders', {
    startDate: orderID ? '' : startDate,
    endDate: orderID ? '' : endDate,
    orderIDOverride: orderID ?? '',
    perPage,
    page,
  });

  const couponPrefixes = await getCouponPrefixes();
  const couponPrefixesArray = couponPrefixes?.map((prefix: any) => prefix.couponPrefix.toLowerCase());
  const orderHighlightingRules = await getOrderHighlightingRules();

  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  let nextPage: number | null = Number(page) ? Number(page) + 1 : 1;
  if (response.data.details.orders.length < (perPage ?? 0)) {
    nextPage = null;
  }
  let nextCursor = null;

  if (response.data.details.orders.length === perPage) {
    // Use the last record's ID as the next cursor
    nextCursor = response.data.details.orders[response.data.details.orders.length - 1].orderID;
  }

  const sortedData = response.data.details.orders.reduce((acc: any, order: any) => {
    if (order.status) {
      // additional order status conditions
      const noDriverTask = order.deliveryStarted === null && order.deliveryCompleted === null;
      const cancelled = order.isCancelled === 1 && order.isCompleted === 1;
      const noItemsFound = cancelled && Number(order.status) !== 5 && Number(order.shopperID) > 0 && noDriverTask;
      const onHold = order.onHold === 1 && (Number(order.status === 2) || Number(order.status === 0));

      if (noItemsFound) {
        order.status = 8;
      } else if (onHold) {
        order.status = 9;
      }
    }

    order.runningLate = isRunningLate(order);

    order.completedLate = isCompletedLate(order);
    order.hasPickupOrDropOffException = hasPickupOrDropOffException(order);

    order.magic = evaluateHighlightingRules(order, orderHighlightingRules);

    order.isCampaignCoupon = order.couponCode
      ? couponPrefixesArray?.some((prefix: string) => order.couponCode.toLowerCase().indexOf(prefix) === 0)
      : false;

    return Number(order.customerType) === 4 ? [order, ...acc] : [...acc, order];
  }, []);

  const sortVip = sortedData.sort((a: any, b: any) =>
    Number(a.customerType) === 4 && Number(b.customerType) === 4 ? -1 : 0
  );

  return { data: sortVip, nextPage, hasMore: nextPage != null, nextCursor };
}

export async function getMalls(props: Token) {
  const { accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + '/v1/spotlight/orders/getStores', {});
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function getCustomerDetails(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/customerDetails`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function getShoppingList(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/shoppingList`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function assignOrder(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/assignOrder`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function holdOrder(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/holdOrder`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function resetOrder(props: Reset) {
  const { accessToken, orderID, resetShopper, hasRefundProof, driverHasOrder, orderPictureProof, reason } = props;
  const url =
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/reset?resetShopper=${resetShopper}`;

  const response = await axiosInstance.put(
    url,
    { hasRefundProof, driverHasOrder, orderPictureProof, reason },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function postToPicup(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/picup/${orderID}`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function postToWumdrop(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/wumdrop/${orderID}`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function postToLoop(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/loop/${orderID}`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function cancelOrder(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/cancel`;

  const response = await axiosInstance.post(
    url,
    {
      cancelReason: 5,
    },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function markCalled(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/markCalled`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function rescheduleOrder(props: Reschedule) {
  const { accessToken, orderID, deliveryDate, deliveryFrom } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/reschedule`;

  const response = await axiosInstance.put(
    url,
    { deliveryDate, deliveryFrom },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function getTransactions(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/transactions`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function getStores(props: Token) {
  const { accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/getStores`, {});
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function getResetPermission(props: Order): Promise<
  AxiosResponse<{
    details: {
      permissions: string[];
      needsRefundProof: boolean;
      needsDriverProof: boolean;
    };
    message: string;
  }>
> {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/resetPermission`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function getComments(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/getComment`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function changeStore(props: ChangeStore) {
  const { accessToken, orderID, storeName } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/changeStore`;

  const response = await axiosInstance.post(
    url,
    { storeName },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function makeComment(props: Comment) {
  const { accessToken, orderID, comment } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/addComment`;

  const response = await axiosInstance.post(
    url,
    { comment },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function getReceiptData(props: GetReceiptProps) {
  const { email, orderID, accessToken } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/fulfilledOrder`,
    {
      email,
    }
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function getChatAuth(props: Token) {
  const { accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/chat/staff/authenticate`, {});
  const response = await axiosInstance
    .get(url.toString(), {
      headers: {
        Authorization: accessToken,
      },
    })
    .catch((e) => {
      return { error: e.message };
    });

  return response;
}

export async function getOrderHighlightingRules() {
  const url = `${process.env.REACT_APP_SLS_API_ENDPOINT}/v1/spotlight/order/highlighting/rules`;
  const data = await axiosInstance
    .get(url.toString())
    .then((response) => response.data)
    .catch(({ message }) => ({ message, details: [] }));
  return getIndexedRagRules(data.details);
}

export async function getMallSlots(props: any) {
  try {
    const { accessToken } = props;
    const { data } = await axiosInstance.get(`${process.env.REACT_APP_SLS_API_ENDPOINT}/v1/spotlight/malls/slots`, {
      headers: { Authorization: accessToken },
    });

    const allSlots = {
      woolworthsSlots: data?.details?.woolworthsSlots,
      onecartSlots: data?.details?.onecartSlots,
    };

    return allSlots;
  } catch (error) {
    console.log(error);
    return { woolworthsSlots: [], onecartSlots: [] };
  }
}

export async function getCouponPrefixes() {
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + '/v1/spotlight/coupon/prefixes', {});
  const response = await axiosInstance.get(url.toString(), {});
  return response.data.details;
}

export async function getAnalytics(props: { startDate: string; endDate: string }) {
  const { startDate, endDate } = props;
  const accessToken = store.get('spotlightAccessToken');
  const slsURL = `${process.env.REACT_APP_SLS_API_ENDPOINT}/v1/spotlight/analytics`;

  const response = await axiosInstance.get(getUrlWithQueryParams(slsURL, { startDate, endDate }).toString(), {
    headers: { Authorization: accessToken },
  });

  const {
    ordersPerTimeslot = [],
    deliverySummary: deliverySummaryData = [],
    orderSummary: orderSummaryData = [],
  } = response?.data?.details ?? {};

  const orderSummary: AnalyticsOrderSummary = orderSummaryData.map((item: AnalyticsOrderSummary) => {
    const { completed, completedEarly, completedOnTime, completedLate } = item;
    return {
      ...item,
      percCompletedEarly: (completedEarly / (completed || completedEarly || 1)) * 100,
      percCompletedOnTime: (completedOnTime / (completed || completedOnTime || 1)) * 100,
      percCompletedLate: (completedLate / (completed || completedLate || 1)) * 100,
    };
  })?.[0];

  const deliverySummary: AnalyticsDeliverySummary = deliverySummaryData[0];

  return { orderSummary, ordersPerTimeslot, deliverySummary };
}

export async function getInvoiceData(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/invoice/${orderID}`, {});
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
}

export async function getActionTypes() {
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/action/types`, {});

  return await axiosInstance.get(url.toString(), {
    headers: {},
  });
}

export async function getOrderActivityHistory(props: Order) {
  const { accessToken, orderID } = props;

  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/activity/history`,
    {}
  );

  return await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });
}

export async function getOrderActivityHistoryDetails(props: Action) {
  const { accessToken, actionID } = props;

  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/activity/history/details/${actionID}`,
    {}
  );

  return await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });
}

export async function getActionOrders(props: GetOrdersProps) {
  const { accessToken, startDate, endDate } = props;

  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/action/orders`, {
    startDate,
    endDate,
  });

  return await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });
}

export async function getShopperStatusOptions() {
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/shopper/status/options`,
    {}
  );

  return await axiosInstance.get(url.toString(), {
    headers: {},
  });
}

export async function getShopperStatuses(props: Token) {
  const { accessToken } = props;

  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/shopper/statuses`, {});

  return await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });
}

export async function getVendors() {
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/shopper/vendors`, {});

  return await axiosInstance.get(url.toString(), {
    headers: {},
  });
}

export async function algoliaSearchSuggestions({ query }: QueryProps) {
  const index = algoliaSearch.initIndex('products_query_suggestions');
  try {
    const results = await index.search<SearchSuggestionsResponse>(query, {
      hitsPerPage: 5,
    });
    return results.hits;
  } catch (error) {
    console.error('Algolia search error:', error);
    return [];
  }
}

export async function searchAlgoliaIndex(
  searchTerm: string,
  options?: Record<string, unknown>
): Promise<SearchResponse<AlgoliaProduct>> {
  const index = algoliaSearch.initIndex('products');
  try {
    const result = await index.search<AlgoliaProduct>(searchTerm, options);
    return result;
  } catch (err) {
    console.error('Algolia search error:', err);

    return {
      hits: [],
      nbHits: 0,
      page: 0,
      nbPages: 0,
      hitsPerPage: 0,
      processingTimeMS: 0,
    };
  }
}

export async function similarProducts(props: Product) {
  const { productID } = props;
  const url = process.env.REACT_APP_ONECART_NODE_API + `/getSimilarProduct`;

  const response = await axiosInstance.post(
    url,
    {
      pageNumber: 1,
      perpage: 20,
      productID,
      storeID: 322,
    },
    {
      headers: {},
    }
  );

  return response;
}

export async function markProductFound(props: ProductFound) {
  const { accessToken, selectedProduct, quantity, barcodes, forceBarcodes, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/shopper/order/completed/product`;

  const response = await axiosInstance.post(
    url,
    {
      barcodes,
      forceBarcodes,
      productID: selectedProduct.productID,
      qtyFound: quantity,
      orderID,
    },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response;
}

export async function markProductNotFound(props: ProductNotFound) {
  const { accessToken, selectedProduct, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/shopper/order/refund/product`;

  const response = await axiosInstance.post(
    url,
    {
      productID: selectedProduct.productID,
      orderID,
    },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function markProductSubstituted(props: SubstituteProduct) {
  const {
    accessToken,
    selectedProduct,
    qtyFound,
    barcodes,
    orderID,
    subQtyFound,
    subProductID,
    subBarcode,
    forceBarcodes,
  } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/shopper/order/substituted/product`;

  const response = await axiosInstance.post(
    url,
    {
      orderID,
      productID: selectedProduct.productID,
      shopperSuggested: true,
      subQtyFound,
      subBarcode,
      forceBarcodes,
      barcodes,
      qtyFound,
      subProductID: subProductID,
    },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response;
}
export async function postOrderToNode(props: PostOrder) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/order/transaction`;

  const response = await axiosInstance.post(
    url,
    {
      orderID,
    },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return response;
}

export async function decodeBarCode(props: BarCode) {
  const { accessToken, barcode } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + '/v1/shopper/barcode/decode', { barcode });
  const response: AxiosResponse<ServerlessBaseResponse<BarcodeDecode>> = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
