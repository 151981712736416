import React, { useImperativeHandle, useMemo } from 'react';
import { useTable, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { Button, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import VirtualizedProductsTable from './VirtualProductsTable';
import { useQuery, useMutation } from 'react-query';
import * as store from 'store';
import { decodeBarCode, getShoppingList } from '../api';
import ModifyOrderModal from './Modals/ModifyOrderModal';
import { useModalContext } from 'contexts/ModalContext';
import { Column, Product, TableProps, Vendor } from 'types';
import { createToastMessage } from './toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '69vh',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '69vh',
      },
    },
    addBarcodeButton: {
      textDecoration: 'underline',
      color: 'gray',
      padding: '6px 12px',
      fontWeight: 'normal',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
        background: 'transparent',
      },
    },
  })
);

const ProductsTable = React.forwardRef((props: TableProps, ref: any) => {
  const { orderID } = props;
  const { setModalOpen, setSelectedProduct, setIsWeighted } = useModalContext();

  const classes = useStyles();

  const accessToken = store.get('spotlightAccessToken');
  const { data, refetch } = useQuery(['receiptShopping', accessToken, orderID], () =>
    getShoppingList({ accessToken, orderID })
  );

  const getBarCodeParts = useMutation(decodeBarCode);

  const checkIsWeighted = async (barcode: string): Promise<boolean> => {
    if (!accessToken) return false;

    try {
      const result = await new Promise<boolean>((resolve, reject) => {
        getBarCodeParts.mutate(
          { accessToken, barcode },
          {
            onSuccess: (data) => {
              if (data.data.details.barcode.weighted) {
                resolve(true);
              } else {
                resolve(false);
              }
            },
            onError: (error) => {
              createToastMessage({ message: error, error: true });
              reject(false);
            },
          }
        );
      });
      return result;
    } catch (error) {
      return false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const vendors = data?.data?.details || [];

  const allProducts = useMemo(() => vendors.flatMap((vendor: Vendor) => vendor.products), [vendors]);

  const ProductColumns: Column[] = [
    { Header: 'Product Details', accessor: 'name' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Price', accessor: 'price' },
    { Header: 'Quantity', accessor: 'orderQuantity' },
    {
      Header: 'Action',
      accessor: 'vendor',
      Cell: ({ row }: { row: any }) => (
        <Button onClick={() => handleEdit(row.original)} variant="text" className={classes.addBarcodeButton}>
          Edit product
        </Button>
      ),
    },
  ];
  const handleEdit = async (product: Product) => {
    setModalOpen(true);
    const isWeighted = await checkIsWeighted(product.sku);
    setSelectedProduct(product);
    setIsWeighted(isWeighted);
  };

  const columns = useMemo(() => ProductColumns, []); // eslint-disable-line react-hooks/exhaustive-deps

  const instance = useTable(
    {
      columns,
      data: allProducts,
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  useImperativeHandle(ref, () => instance);

  return (
    <Paper className={classes.root}>
      <VirtualizedProductsTable instance={instance} />
      <ModifyOrderModal orderID={orderID} onSuccess={() => refetch()} />
    </Paper>
  );
});

export default ProductsTable;
