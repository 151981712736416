import React, { FunctionComponent } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useOrdersContext } from '../contexts/OrdersContext';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface DateFilterProps {
  mobile?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  datepicker: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

const DateFilter: FunctionComponent<DateFilterProps> = (props) => {
  const classes = useStyles();
  const margin = !props.mobile ? 'normal' : 'dense';

  const { startDate, setStartDate, setEndDate
    // ,endDate
   } = useOrdersContext();

  const handleStartDateChange = (value: MaterialUiPickersDate) => {
    if (value)  {
      setStartDate(value.toISOString().slice(0, 10));
      setEndDate(value.toISOString().slice(0, 10));
    }
  };

  // const handleEndDateChange = (value: MaterialUiPickersDate) => {
  //   if (value) setEndDate(value.toISOString().slice(0, 10));
  // };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        inputVariant="outlined"
        variant="inline"
        format="dd/MM/yyyy"
        id="startDate"
        label="Date"
        className={classes.datepicker}
        value={startDate}
        onChange={handleStartDateChange}
        margin={margin}
        fullWidth
      />
      {/* <DatePicker
        inputVariant="outlined"
        variant="inline"
        format="MM/dd/yyyy"
        id="endDate"
        label="To"
        className={classes.datepicker}
        value={endDate}
        onChange={handleEndDateChange}
        margin={margin}
        fullWidth
      /> */}
    </MuiPickersUtilsProvider>
  );
};

export default DateFilter;
