import { makeStyles } from '@material-ui/core/styles';

export const useAnalyticsStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    minHeight: '100vh',
    display: 'flex',
  },
  header: {
    width: '100%',
    minHeight: '75px',
  },
  logo: {
    width: '25px',
    objectFit: 'contain',
  },
  appBar: {
    width: '100%',
    color: '#44444F90',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
    },
  },
  stickyHeader: {
    zIndex: 10,
    width: '100%',
    maxWidth: '100vw',
    position: 'fixed',
    borderBottom: '1px solid #E0E0E0',
  },
  toolBar: {
    minHeight: '60px',
  },
  menuButton: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  title: {
    display: 'none',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up(1281)]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  tabs: {
    minHeight: '95%',
  },
  indicator: {
    backgroundColor: '#57A946',
    marginLeft: 55,
    height: 5,
    maxWidth: 50,
    width: '100%',
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    textTransform: 'none',
  },
  sectionDesktop: {
    display: 'none',
    '& $filters': {
      gap: '15px',
      padding: '0 24px 0 8px ',
      gridTemplateColumns: '1fr auto',
    },
    '& $filterHeading': {
      marginBottom: '0px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menu: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  grow: {
    flexGrow: 1,
  },
  badgeColor: {
    color: '#11B0F2',
  },
  filters: {
    display: 'grid',
    alignItems: 'center',
    padding: '0 24px 0 8px ',
    gridTemplateColumns: '1fr auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      padding: '0 20px 10px',
    },
  },
  filterWrapper: {
    padding: ' 15px 20px 0',
  },
  filterHeading: {
    marginTop: '1px',
    color: 'rgba(0,0,0,.6)',
    letterSpacing: '-0.5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: '16px',
    },
  },
  filterSection: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gap: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '8px',
      width: '100%',
      flexDirection: 'column',
    },
  },
  dateFilter: {
    display: 'flex',
    marginLeft: '10px',
    borderRadius: '3px',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'space-between',
    border: '1px solid rgba(0,0,0,.3)',
    backgroundColor: '#E2E2E2',
    color: 'rgba(0,0,0,.6)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
    },
  },
  filterName: {
    padding: '0 10px',
    lineHeight: '30px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    backgroundColor: '#FFFFFF',
    fontSize: '13px',
    [theme.breakpoints.down('xs')]: {
      width: '60px',
    },
  },
  filterInput: {
    border: '0 !important',
    outline: '0 !important',
    background: 'transparent',
    marginLeft: '10px',
    marginRight: '4px',
  },

  summarySection: {
    gap: '15px',
    padding: '20px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(4, 1fr)',
    maxWidth: '1440px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  summaryCard: {
    borderRadius: '4px',
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    [theme.breakpoints.down('md')]: {
      '&:last-child': {
        gridColumnEnd: '-1',
        gridColumnStart: 'span 2',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        gridColumnEnd: '-1',
        gridColumnStart: 'span 1',
      },
    },
    '&.placeholder': {
      border: 'none',
      background: 'transparent',
    },
  },
  summaryCardCaption: {
    fontSize: '17px',
    padding: '10px 20px 12px',
    borderBottom: '1px solid #E2E8F0',
  },
  summaryCaptionText: {
    fontWeight: 500,
    lineHeight: '1.5',
    color: '#505050',
  },
  summaryCardValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px 30px',
    lineHeight: '1',
    fontWeight: 600,
    fontSize: '110px',
    color: '#000000',
  },
  tableSection: {
    gap: '15px',
    padding: '20px',
    maxWidth: '1440px',
    margin: '0 auto',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
}));
