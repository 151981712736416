import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useWindowParams } from '../../hooks/useWindowParams';

const useStyles = makeStyles(() => ({
  btn: {
    marginTop: '6px',
    letterSpacing: '0.5px',
    borderRadius: ({ isWW }: any) => isWW ? '0px' : '4px',
    background: ({ isWW }: any) => isWW ? '#343434' : '#57A946',
    textTransform: ({ isWW }: any) => isWW ? 'uppercase' : 'none',
    color: ({ isWW }: any) => isWW ? '#fff' : '#000',
    '&:hover': {
      background: ({ isWW }: any) => isWW ? '#343434' : '#57A946',
    }
  },
}));

function LoginButton() {
  const { loginWithRedirect } = useAuth0();
  const { isWoolies: isWW } = useWindowParams();
  const classes = useStyles({ isWW });
  return (
    <Button
      onClick={() => loginWithRedirect({ redirectUri: process.env.REACT_APP_AUTH0_REDIRECT })}
      className={classes.btn}
      variant="contained"
    >
      Login
    </Button>
  )
}

export default LoginButton
