import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import { getShoppingList } from '../api';
import { useQuery } from 'react-query';
import * as store from 'store';

interface VandorListProps {
  orderID: string;
}

interface Product {
  imageURL: string;
}
interface Vendor {
  vendor: string;
  products: Product[];
  cost: string;
}
interface VendorsProps {
  vendors: Vendor[];
}
interface IState {
  shopper: string;
  isCompleted: string;
  orderTypeID: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      height: '70vh',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    listText: {
      margin: theme.spacing(2),
    },
    arrowForward: {
      marginTop: theme.spacing(4),
      color: '#92929D',
    },
  })
);

const Vendors: FunctionComponent<VendorsProps> = (props) => {
  const classes = useStyles();
  let { url } = useRouteMatch();
  const location = useLocation();

  const isCompleted = (location.state as IState)?.isCompleted;
  const orderTypeID = (location.state as IState)?.orderTypeID;

  const { vendors } = props;

  const parseImage = (vendor: string) => {
    const trimmed = vendor.trim();
    const imageUrl = `https://onecart.s3-eu-west-1.amazonaws.com/website/logos/${trimmed}.png`;
    return imageUrl;
  };

  return (
    <List component="nav" className={classes.root}>
      {vendors.length > 0 &&
        vendors.map((vendor) => {
          let imageUrl = '';
          if (vendor.products.length > 0) {
            imageUrl = parseImage(vendor.vendor);
          }
          return (
            <Link
              style={{ textDecoration: 'none' }}
              to={{ pathname: `${url}/${vendor.vendor}`, state: { vendor, isCompleted, orderTypeID } }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatar} alt="Remy Sharp" src={`${imageUrl}`} />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listText}
                  primary={`${vendor.products.length} items`}
                  secondary={`R${Number(vendor.cost).toFixed(2)}`}
                />
                <ArrowForwardIosIcon className={classes.arrowForward} />
              </ListItem>
              <Divider />
            </Link>
          );
        })}
    </List>
  );
};

const VendorsList: FunctionComponent<VandorListProps> = (props) => {
  const { orderID } = props;
  let vendors = [];
  const accessToken = store.get('spotlightAccessToken');

  const { data } = useQuery(['shoppingList', accessToken, orderID], () => getShoppingList({ accessToken, orderID }));
  if (data?.data) {
    vendors = data?.data.details;
  }

  return <Vendors vendors={vendors} />;
};

export default VendorsList;
