import React, { FunctionComponent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      borderRadius: '16px'
    },

    chip: {
      width: '100%',
      minWidth: 100,
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        fontSize: 8,
        minWidth: 20,
        fontWeight: 500,
        height: '28px',
        letterSpacing: '0.4px'
      },
    },
  })
);

enum OrderStatusEnum {
  Invalid = 0,
  Completed = 1,
  'Not started' = 2,
  Active = 3,
  Late = 4,
  Cancelled = 5,
  'On the way' = 6,
  'Pending driver' = 7,
  'No items found' = 8,
  'On hold' = 9,
  'Pickup exception'=10,
  'Drop off exception'=11,
  'Pickup started' = 12,
  'Pickup completed' = 13
}

enum StatusColorEnum {
  Invalid = 'rgb(176,178,183, 0.2)',
  Completed = 'rgb(133,204,102, 0.2)',
  'Not started' = 'rgb(238,170,35, 0.2)',
  Active = 'rgb(17,176,242,0.2)',
  Late = 'rgb(214,87,69, 0.3)',
  Cancelled = 'rgb(128,0,128, 0.2)',
  'On the way' = 'rgb(17,176,242,0.2)',
  'Pending driver' = 'rgb(17,176,242,0.2)',
  'No items found' = 'rgb(128,0,128, 0.2)',
  'On hold' = 'rgb(128,0,128, 0.2)',
  'Pickup exception' = 'rgb(255,0,0)',
  'Drop off exception' = 'rgb(255,0,0)',
  'Pickup started' = 'rgb(17, 176, 242, 0.2)',
  'Pickup completed' = 'rgb(17, 176, 242, 0.2)'
}

interface OrderStatusProps {
  status: OrderStatusEnum;
}

const OrderStatus: FunctionComponent<OrderStatusProps> = (props) => {
  const classes = useStyles();
  const { status} = props;

  const statusName = OrderStatusEnum[status];
  const statusColor = StatusColorEnum[statusName as keyof typeof StatusColorEnum];

  return (
    <div className={classes.root} style={{ backgroundColor: 'white' }}>
      <Chip className={classes.chip} label={statusName} style={{ backgroundColor: statusColor }} />
    </div>
  );
};

export default OrderStatus;
