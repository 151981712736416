import React, { createContext, useContext, useState } from 'react';
import { Product } from '../types';

interface ModalContextType {
  isModalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  selectedProduct: Product | null;
  setSelectedProduct: (product: Product | null) => void;
  editedProducts: Product[];
  setEditedProducts: (products: Product[]) => void;
  isWeighted: boolean;
  setIsWeighted: (isWeighted: boolean) => void;
  loaderModal: boolean;
  setLoaderModal: (loading: boolean) => void;
  transactionStatusModal: boolean;
  setTransactionStatusModal: (loading: boolean) => void;
}

const ModalContext = createContext<ModalContextType>({
  isModalOpen: false,
  setModalOpen: () => {},
  selectedProduct: null,
  setSelectedProduct: () => {},
  editedProducts: [],
  setEditedProducts: () => {},
  isWeighted: false,
  setIsWeighted: () => {},
  loaderModal: false,
  setLoaderModal: () => {},
  transactionStatusModal: false,
  setTransactionStatusModal: () => {},
});

export const useModalContext = () => useContext(ModalContext);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [editedProducts, setEditedProducts] = useState<Product[]>([]);
  const [isWeighted, setIsWeighted] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);
  const [transactionStatusModal, setTransactionStatusModal] = useState<boolean>(false);

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setModalOpen,
        selectedProduct,
        setSelectedProduct,
        editedProducts,
        setEditedProducts,
        isWeighted,
        setIsWeighted,
        loaderModal,
        setLoaderModal,
        transactionStatusModal,
        setTransactionStatusModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
