import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useOrdersContext } from '../../contexts/OrdersContext';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '90%',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(768)]: {
      minHeight: '20px',
      minWidth: '90%',
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      minHeight: '20px',
      minWidth: '90%',
    },
  },
  label: {
    backgroundColor: 'white',
    [theme.breakpoints.down(768)]: {
      fontSize: 13,
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      fontSize: 13,
      left: 8,
    },
  },
  select: {
    minWidth: '280px',
    fontSize: '15px',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
      height: '40px',
      minWidth: '100%',
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      fontSize: '12px',
      height: '40px',
      minWidth: '100%',
    },
  },
  menuPaper: {
    maxHeight: 300,
    fontSize: '18px',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
      margin: theme.spacing(0),
    },
    [`${theme.breakpoints.down(920)} and (orientation: landscape)`]: {
      fontSize: '12px',
      margin: theme.spacing(0),
    },
  },
}));

const MobileVendorFilter: FunctionComponent = () => {
  const classes = useStyles();
  const { vendor, setVendor } = useOrdersContext();

  const handleVendorChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setVendor(event.target.value as string);
  };

  return (
    <Grid item sm={4} xs={12}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="mall-label" className={classes.label}>
          Vendor
        </InputLabel>
        <Select
          label="Choose mall"
          labelId="mall-select-label"
          id="mall-select"
          value={vendor}
          onChange={handleVendorChange}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          className={classes.select}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Onecart">Onecart</MenuItem>
          <MenuItem value="Woolworths">Woolworths</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

export default MobileVendorFilter;
