import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomModal from './CustomModal';
import List from '@material-ui/core/List';
import Product from './ProductAccordion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  expandIcon: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  itemDetails: {
    margin: theme.spacing(2),
  },
}));

function ProductList(props) {
  const { fulfilledOrder } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      {fulfilledOrder.map((list) => (
        <List className={classes.root}>
          <Typography variant="h6" gutterBottom>
            {list.name}
          </Typography>
          <Typography gutterBottom>Total: {list.foundTotal + list.substitutedTotal + list.partialTotal}</Typography>
          {list.foundItems.map((item) => (
            <Product
              key={item.productID}
              itemDetails={item}
              classes={classes}
              expanded={expanded}
              statusColor="green"
            />
          ))}
          {list.outOfStockItems.map((item) => (
            <Product key={item.productID} itemDetails={item} classes={classes} expanded={expanded} statusColor="red" />
          ))}
          {list.substitutedItems.map((item) => (
            <Product
              key={item.productID}
              itemDetails={item}
              classes={classes}
              expanded={expanded}
              handleChange={handleChange(item.productID)}
              statusColor="yellow"
            />
          ))}
          {list.partialItems.map((item) => (
            <Product
              key={item.productID}
              itemDetails={item}
              classes={classes}
              expanded={expanded}
              statusColor="yellow"
            />
          ))}
        </List>
      ))}
    </div>
  );
}
export default function FulfilledOrder(props) {
  const { accessToken, orderId, openFullfilledOrder, closeFulfilledOrder } = props;

  const [fulfilledOrder, setFulfilledOrder] = useState([]);
  useEffect(() => {
    if (orderId && accessToken && !fulfilledOrder.length) fetchFulfilledOrder();
  });

  const fetchFulfilledOrder = async () => {
    const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/fulfilledOrder`;

    const response = await fetch(url, {
      headers: { Authorization: accessToken },
    })
      .then((resp) => resp.json())
      .catch((error) => console.log(error));
    setFulfilledOrder(response.details.products);
  };
  return (
    <CustomModal title="Fulfilled order" openModal={openFullfilledOrder} closeModal={closeFulfilledOrder}>
      {fulfilledOrder.length > 0 && <ProductList fulfilledOrder={fulfilledOrder} />}
    </CustomModal>
  );
}
