import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import React, { useRef, useState } from 'react';
import { TableInstance } from 'react-table';
import * as store from 'store';
import { Header } from '../components';
import EmptyState from '../components/empty';
import MobileOrdersTable from '../components/mobile/MobileOrdersTable';
import { useWindowParams } from '../hooks/useWindowParams';
import { OrderSummary } from '../types';
import { useOrdersContext } from '../contexts/OrdersContext';
// import { ChatContext } from '../contexts/ChatContext';
// Un-comment Chat when chat it ready

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      paddingTop: '0.2%',
      marginBottom: '0.1%',
      marginRight: '0.5%',
      [theme.breakpoints.down(768)]: {
        padding: '0%',
      },
    },

    paper: {
      // padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      width: '100%',
      position: 'relative',
      [theme.breakpoints.down(768)]: {
        padding: theme.spacing(0.5),
      },
    },

    container: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
    },

    wrapper: {
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        padding: '5px',
        paddingTop: '0px',
      },
    },
  })
);

const DashOrders: React.FC = () => {
  const { isMobile } = useWindowParams();
  const tableInstance = useRef<TableInstance>(null);
  const classes = useStyles();
  // const { setOrders } = useContext(ChatContext);

  const { orders, result } = useOrdersContext();

  if (orders !== undefined) {
    //get stores for current orders in order to filter out mall selection filter
    const currentStores = orders.map((value: any) => value.shopName);
    store.remove('currentStores');
    store.set('currentStores', currentStores);
  }

  const [ordersSummary, setOrdersSummary] = useState<OrderSummary>({
    totalOrders: 0,
    totalCompleted: 0,
    completedLate: 0,
    notStarted: 0,
    runningLate: 0,
    inProgress: 0,
    cancelled: 0,
  });

  return (
    <React.Fragment>
      <Header tableInstance={tableInstance} activeTab={'orders'} isMobile={isMobile} summaryData={ordersSummary} />

      <div className={classes.root}>
        <Grid container spacing={0}>
          <TableContainer component={Paper}>
            <Grid container className={classes.container}>
              <Box p={3} className={classes.wrapper}>
                <Box>
                  <Paper className={classes.paper} component={'div'}>
                    {!!result.isLoading && <EmptyState loading title="Loading Orders..." />}
                    {!result.isLoading && (
                      <MobileOrdersTable
                        orders={orders}
                        ref={tableInstance}
                        setOrdersSummary={setOrdersSummary}
                        isMobile={isMobile}
                      />
                    )}
                  </Paper>
                </Box>
              </Box>
            </Grid>
          </TableContainer>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default DashOrders;
