import React from 'react';
import * as store from 'store';
import './AlertNotification.css';

export default function AlertNotification({ orderID, title, message, icon }) {
    const getNotifications = store.get('notifications');
    const notifications = getNotifications ? JSON.parse(getNotifications) : [];

    const handleClick = (orderID) => {
        // e.preventDefault();

        const newList = notifications.map((item) => {
            if (item.orderID === orderID) item.isRead = true;
            return item;
        });

        //update list
        store.set('notifications', JSON.stringify(newList));

        window.location.href = `/orders/${orderID}`;
   };

    return (
        <>
            <div className="notification" onClick={() => handleClick(orderID)}>
                <div className="notification-image">
                    <img src={icon} alt="logo" />
                </div>
                <div>
                    <p className="notification-title">{title}</p>
                    <p className="notification-message">
                        {message}
                    </p>
                </div>
            </div>
        </>
    )
}