import React, { useContext, useState } from 'react';
import { Order } from '../types';
import { useInfiniteQuery } from 'react-query';
import { getOrders } from '../api';
import * as store from 'store';

interface OrdersContextType {
  vendor: string;
  setVendor: (vendor: string) => void;

  startDate: string;
  setStartDate: (startDate: string) => void;

  endDate: string;
  setEndDate: (endDate: string) => void;

  orderID: string | null;
  setOrderID: (orderID: string | null) => void;

  selectedStores: string[];
  setSelectedStores: (selectedStores: string[]) => void;

  selectedStatus: number[];
  setSelectedStatus: (selectedStatus: number[]) => void;

  orders: Order[];
  //setOrders: (selectedStatus: Order[]) => void;

  result: {[arg: string]: any;}
}

const OrdersContext = React.createContext<OrdersContextType>({
  vendor: 'All',
  setVendor: (vendor) => vendor,

  startDate: new Date().toISOString().slice(0, 10),
  setStartDate: (startDate) => startDate,

  endDate: new Date().toISOString().slice(0, 10),
  setEndDate: (endDate) => endDate,

  orderID: null,
  setOrderID: (orderID) => orderID,

  selectedStores: [],
  setSelectedStores: (selectedStores) => selectedStores,

  selectedStatus: [],
  setSelectedStatus: (selectedStatus) => selectedStatus,

  orders: [],
  //setOrders: (orders) => orders,

  result: {}
});

export const useOrdersContext = () => useContext(OrdersContext);

export const OrdersProvider = ({ children }: { children: React.ReactNode }) => {
  const [vendor, setVendor] = useState('All');
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [orderID, setOrderID] = useState<string | null>(null);
  const [selectedStores, setSelectedStores] = useState<string[] | []>([]);
  const [selectedStatus, setSelectedStatus] = useState<number[] | []>([]);
  const accessToken = store.get('spotlightAccessToken');
  let orders: Order[] = [];
  const {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    data,
    ...result
  } = useInfiniteQuery(
    ['orders', endDate, startDate, orderID, accessToken],
    ({ pageParam = 1 }) => getOrders({ startDate, endDate, orderID, accessToken, perPage: 10000, page: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (!lastPage.nextPage) {
          return undefined;
        }
        // setNextCursor(lastPage.nextCursor)
        return lastPage.nextPage;
      },
    }
  );
  if (data && data.pages && data.pages.length) {
    orders = data.pages.flatMap((page, i) => {
      if (page.hasMore && hasNextPage) {
        fetchNextPage();
      }

      return page.data;
    });
  }

  return (
    <OrdersContext.Provider
      value={{
        vendor,
        setVendor: (vendor) => setVendor(vendor),

        startDate,
        setStartDate: (startDate) => setStartDate(startDate),

        endDate,
        setEndDate: (endDate) => setEndDate(endDate),

        orderID,
        setOrderID: (orderID) => setOrderID(orderID),

        selectedStores,
        setSelectedStores: (selectedStores) => setSelectedStores(selectedStores),

        selectedStatus,
        setSelectedStatus: (selectedStatus) => setSelectedStatus(selectedStatus),

        orders,
        result,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
