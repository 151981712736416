import React from 'react';
import { usePermissions } from '../hooks/usePermissions';
import DashOrders from '../pages/DashOrders';
import Orders from '../pages/Orders';
import MyOrders from '../pages/MyOrders';
import ActionOrders from '../pages/ActionOrders';
import ShopperStatuses from '../pages/ShopperStatuses';
import MallSlots from '../pages/MallSlots';
import NotFound from '../pages/NotFound';
import { SpotlightPermission } from '../types/enums';

const UserRole = (component: string) => {
  const { isPermitted } = usePermissions();
  const OrdersProfile = isPermitted([SpotlightPermission.EDIT_ORDER, SpotlightPermission.ASSIGN_AGENT], 'all');

  if (OrdersProfile) {
    console.log('Orders_Profile');

    switch (component) {
      case 'orders':
        return <Orders />;

      case 'my-orders':
        return <MyOrders />;

      case 'action-orders':
        return <ActionOrders />;

      case 'shopper-statuses':
        return <ShopperStatuses />;

      case 'mall-slots':
        return <MallSlots />;

      default:
        return <NotFound />;
    }
  } else {
    console.log('SuperviorDash_Profile');

    switch (component) {
      case 'shopper-statuses':
        return <ShopperStatuses />;

      default:
        return <DashOrders />;
    }
  }
};

export default UserRole;
