import React from 'react';
import { Avatar, Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Product } from 'types';

interface SelectedSubstituteProps {
  product: Product;
}

const SelectedSubstitute: React.FC<SelectedSubstituteProps> = ({ product }) => {
  const imageURL = product.imageURL || product.ImageURL || 'https://via.placeholder.com/30';
  const productName = product.name;
  const productPrice = product.price
    ? product.price.toFixed(2)
    : product.pricing?.[0]?.originalPrice?.toFixed(2) || 'N/A';

  return (
    <Box display="flex" alignItems="center" bgcolor="rgb(240,240,240)" p={1} borderRadius="24px" width="70%">
      <Avatar src={imageURL} alt={productName} style={{ width: 24, height: 24, marginRight: '10px' }} />
      <Typography
        variant="body2"
        style={{
          flex: 1,
          fontSize: '14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '300px',
        }}
      >
        {productName}
      </Typography>
      <Typography variant="caption" style={{ fontSize: '12px', marginLeft: '8px' }}>
        R{productPrice}
      </Typography>
      <IconButton size="small" style={{ marginLeft: '16px' }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default SelectedSubstitute;
