import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { useTable, useSortBy } from 'react-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { MallSlot, Slot } from '../types';

interface TableProps {
  data: MallSlot[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '80vh',
      [theme.breakpoints.down(768)]: {
        width: '100%',
        height: '69vh',
      },
    },
    container: {
      width: '100%',
      height: '100%',
    },
    tbody: {
      width: '100%',
      height: 'calc(69vh - 82px)',
    },
    header: {
      height: '80px',
      boxSizing: 'border-box',
      backgroundColor: '#fafafa',
      overflow: 'hidden',
    },
    row: {
      minHeight: '80px',
      boxSizing: 'border-box',
      verticalAlign: 'middle',
      border: 'none',
    },
    grid: {
      display: 'grid',
      alignItems: 'center',
      verticalAlign: 'middle',
      gridTemplateRows: 'auto',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    slotContainer: {
      display: 'block',
      backgroundColor: '#fff',
      border: '1px solid #dddddd',
      color: '#57a946',
      borderRadius: '4px',
      padding: '15px 5px',
      cursor: 'pointer',
      fontSize: '11px',
      fontWeight: 500,
      width: '100%',
      textAlign: 'center',
    },
  })
);

function convertTime(time: any) {
  time = time.substring(0, time.length - 3);

  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

interface Column {
  accessor: any;
  Header: string;
  minWidth?: number;
  expandingCell?: boolean;
  align?: 'right';
  format?: (value: number) => string;
  Cell?: any;
  show?: boolean;
  filter?: any;
}

const columns: Column[] = [
  { accessor: 'mallID', Header: 'mall ID', minWidth: 0, show: false },
  {
    accessor: 'name',
    Header: 'Name',
    minWidth: 50,
  },
  {
    Header: 'Next (Estimated) Available Time Slots',
    minWidth: 100,
    accessor: (data: MallSlot) => {
      return (
        <div style={{ display: 'inline-flex' }}>
          {data.slots?.details?.map((slot: Slot, i: number) => {
            return (
              <div key={i}>
                <label
                  style={{
                    display: 'block',
                    border: '1px solid #dddddd',
                    color: '#57a946',
                    borderRadius: '4px',
                    padding: '15px 10px',
                    fontSize: '11px',
                    fontWeight: 500,
                    textAlign: 'center',
                    marginRight: '5px',
                  }}
                >
                  {convertTime(slot.delivery_from)} - {convertTime(slot.delivery_to)}
                </label>
              </div>
            );
          })}
        </div>
      );
    },
  },
];

const SlotsTable = React.forwardRef((props: TableProps, ref: any) => {
  const classes = useStyles();

  const { data } = props;

  const allColums = useMemo(() => columns, []);
  const mallSlots = useMemo(() => data, [data]);

  const instance = useTable({
    columns: allColums,
    data: mallSlots,
    initialState: {
      hiddenColumns: allColums.filter((col: any) => col.show === false).map((col: any) => col.accessor),
    },
    useSortBy,
  });

  const { rows, getTableProps, getTableBodyProps, headerGroups, prepareRow } = instance;

  // return table instance
  useImperativeHandle(ref, () => instance);
  const tableContainerRef = useRef<HTMLInputElement>(null);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} ref={tableContainerRef}>
        {/* <VirtualizedTable instance={instance} /> */}
        <Table {...getTableProps()} stickyHeader aria-label="sticky table">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} className={classes.row}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className={classes.row}>
                  {row.cells.map((cell: any) => (
                    <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

export default SlotsTable;
