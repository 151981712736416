import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import * as store from 'store';
import CustomIconButton from '../../components/CustomIconButon';
import logo from '../../images/woolworths.png';
import AnalyticsFilters, { FilterProps } from './Filters';
import { useAnalyticsStyles } from './Styles';

function Header(props: FilterProps) {
  const classes = useAnalyticsStyles();
  const { logout } = useAuth0();
  const [anchorEl, setMenuAnchorEl] = useState(null);

  const handleMenu = (event: any) => {
    setMenuAnchorEl(event?.currentTarget ?? null);
  };

  const handleLogout = () => {
    store.clearAll();
    logout({ returnTo: window.location.origin });
  };

  const menuId = 'primary-search-account-menu';

  const menuItems = [{ label: 'Log Out', action: handleLogout, device: 'desktop' }];

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <CustomIconButton edge="start" className={classes.menuButton}>
            <img alt="spotlight logo" className={classes.logo} src={logo} />
          </CustomIconButton>

          <Typography className={classes.title} variant="h6" noWrap>
            {`Analytics: `}
          </Typography>

          <div className={classes.grow}>
            <div className={classes.sectionDesktop}>
              <AnalyticsFilters {...props} />
            </div>
          </div>

          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              className={classes.menuButton}
              aria-label="user account"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
              <ExpandMore />
            </IconButton>
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              className={classes.menuButton}
              aria-label="show more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <ContextMenu id={menuId} anchor={anchorEl} onMenuClose={() => handleMenu(null)} items={menuItems} />
    </>
  );
}

function ContextMenu({ id, anchor, onMenuClose, items = [] }: any) {
  const classes = useAnalyticsStyles();

  const onClickMenuItem = (action: any) => {
    typeof action === 'function' && action();
    return onMenuClose();
  };

  return (
    <Menu
      id={id}
      anchorEl={anchor}
      onClose={onMenuClose}
      open={Boolean(anchor)}
      classes={{ list: classes.menu }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
    >
      {items.map((item: any, index: number) => {
        const { device, label, divider, action } = item;

        const className = device === 'mobile' ? classes.sectionMobile : '';

        if (!!divider)
          return <Divider className={classes.sectionMobile} key={`divider-${index}`} style={{ margin: '8px 10px' }} />;

        return (
          <MenuItem key={`${label}-${index}`} className={className} onClick={() => onClickMenuItem(action)}>
            {label}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default function AnalyticsHeader(props: FilterProps) {
  const classes = useAnalyticsStyles();
  return (
    <div className={`${classes.grow} ${classes.header}`}>
      <div className={classes.stickyHeader}>
        <Header {...props} />
      </div>
    </div>
  );
}
