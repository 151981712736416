import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { getOrderActivityHistory, getOrderActivityHistoryDetails, getActionTypes } from '../api';
import * as store from 'store';
import MuiDialogActions from '@material-ui/core/DialogActions';
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Collapse,
  Typography,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import EmptyState from '../components/empty';
import ActivityHistoryFilters from './ActivityHistoryFilters';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import CustomModal from './CustomModal';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import SpinnerIcon from '@material-ui/core/CircularProgress';

interface ActivityHistoryProps {
  orderID: string;
}

interface ActivityHistoryInterface {
  actionID: number;
  description: string;
  agentName: string;
  timestamp: string;
  open: boolean;
}
declare interface ActivityHistoryDetail {
  actionDetailID: number;
  tableName: string;
  attribute: string;
  oldValue: string;
  newValue: string;
}

interface ActionType {
  actionTypeID: number;
  action: string;
  comment: string;
}

interface ActionTypesDialogProps {
  actionTypes: ActionType[];
  openModal: boolean;
  closeModal: () => void;
}

interface ActionDetailsTableProps {
  actionID: number;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    noData: {
      display: 'flex',
      justifyContent: 'center',
      padding: '2.5em 0',
    },
    table: {
      minWidth: 650,
    },
    tableRow: {
      '&:last-child td, &:last-child th': {
        border: 0,
      },
      '& > *': {
        borderBottom: 'unset',
      },
    },
    detailRow: {
      '&:last-child td, &:last-child th': {
        borderBottom: 'unset',
      },
    },
    buttonContainer: {
      display: 'block',
      height: theme.spacing(7),
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      float: 'right',
    },
    spinnerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px 0',
    },
    active: {
      backgroundColor: theme.palette.action.selected,
    },
    inactive: {
      backgroundColor: 'unset',
    },
  })
);

const ActionTypesDialog: FunctionComponent<ActionTypesDialogProps> = (props) => {
  const { actionTypes, openModal, closeModal } = props;
  const classes = useStyles();

  return (
    <CustomModal openModal={openModal} closeModal={closeModal} title={'Spotlight Action Types'} height="auto">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="action types">
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actionTypes.map(({ actionTypeID, action, comment }: ActionType) => (
              <TableRow key={actionTypeID}>
                <TableCell>{action}</TableCell>
                <TableCell>{comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MuiDialogActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={closeModal}>
          Close
        </Button>
      </MuiDialogActions>
    </CustomModal>
  );
};

const ActionDetailsTable: FunctionComponent<ActionDetailsTableProps> = (props) => {
  const accessToken = store.get('spotlightAccessToken');
  const { actionID, open } = props;
  const classes = useStyles();

  const { data, isLoading } = useQuery(['activityHistoryDetails', accessToken, actionID], () =>
    getOrderActivityHistoryDetails({ accessToken, actionID })
  );

  if (isLoading) {
    return (
      <div className={classes.spinnerWrapper}>
        <SpinnerIcon style={{ width: 25, height: 25, marginRight: 8 }} />
        <Typography variant="subtitle1">Loading Details...</Typography>
      </div>
    );
  }

  if (!data?.data.details.length) {
    return (
      <b style={{ textAlign: 'center', display: 'block', padding: '8px 0' }}>
        No activity detail found for this action
      </b>
    );
  }

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <b style={{ textAlign: 'center', display: 'block', padding: '8px 0' }}>Action History Details</b>
        <Table size="small" aria-label="details">
          <TableHead>
            <TableRow>
              <TableCell>Table Name</TableCell>
              <TableCell>Attribute</TableCell>
              <TableCell align="right">Old Value</TableCell>
              <TableCell align="right">New Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.details.map(
              ({ actionDetailID, tableName, attribute, oldValue, newValue }: ActivityHistoryDetail) => (
                <TableRow key={actionDetailID} className={classes.detailRow}>
                  <TableCell component="th" scope="row">
                    {tableName}
                  </TableCell>
                  <TableCell>{attribute}</TableCell>
                  <TableCell align="right">{oldValue ?? 'NULL'}</TableCell>
                  <TableCell align="right">{newValue ?? 'NULL'}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  );
};

const ActivityHistory: FunctionComponent<ActivityHistoryProps> = (props) => {
  const accessToken = store.get('spotlightAccessToken');
  const classes = useStyles();
  const { orderID } = props;

  const { data, isLoading } = useQuery(['activityHistory', accessToken, orderID], () =>
    getOrderActivityHistory({ accessToken, orderID })
  );

  const { data: actionTypes, isLoading: actionTypesLoading } = useQuery(['actionTypes', accessToken], () =>
    getActionTypes()
  );

  const [activityHistory, setActivityHistory] = useState(data?.data.details);
  const [openActionTypesDialog, setOpenActionTypesDialog] = useState(false);

  const setOpen = (actionID: number, isOpen: boolean) => {
    setActivityHistory(
      activityHistory?.map((o: ActivityHistoryInterface) => ({ ...o, open: !isOpen && actionID === o.actionID }))
    );
  };

  if (isLoading || actionTypesLoading) return <EmptyState loading title="Loading Activity History..." />;

  if (!data?.data.details.length) return <div className={classes.noData}>No activity history found for this order</div>;

  if (!actionTypes?.data.details.length) return <div className={classes.noData}>Action types not found!</div>;

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Button
          size="large"
          variant="outlined"
          className={classes.button}
          startIcon={<TouchAppIcon color="primary" />}
          onClick={() => setOpenActionTypesDialog(true)}
        >
          View Action Types
        </Button>

        {openActionTypesDialog && (
          <ActionTypesDialog
            actionTypes={actionTypes.data.details}
            openModal={openActionTypesDialog}
            closeModal={() => setOpenActionTypesDialog(false)}
          />
        )}
      </div>

      <ActivityHistoryFilters
        activityHistoryData={data.data.details}
        actionTypes={actionTypes.data.details}
        setHistory={setActivityHistory}
      />

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="activity history">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Description</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityHistory?.map(({ actionID, description, agentName, timestamp, open }: ActivityHistoryInterface) => {
              const backgroundColor = open ? classes.active : classes.inactive;

              return (
                <React.Fragment key={actionID}>
                  <TableRow className={clsx(classes.tableRow, backgroundColor)}>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(actionID, open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>
                      <b>{agentName}</b>
                    </TableCell>
                    <TableCell>{moment(timestamp).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                  </TableRow>

                  {open && (
                    <TableRow className={clsx(classes.tableRow, backgroundColor)}>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <ActionDetailsTable actionID={actionID} open={open} />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActivityHistory;
