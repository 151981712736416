import { OutlinedInput, ListItemText, InputLabel, MenuItem, Checkbox, Select, FormControl } from '@material-ui/core';
import React, { FunctionComponent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
  },
  label: {
    backgroundColor: 'white',
  },
  menuPaper: {
    maxHeight: 500,
  },
  values: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

interface ShopperVendorsFilterProps {
  mobile?: boolean;
  vendors: { name: string }[];
  selectedVendors: string[];
  setSelectedVendors: React.Dispatch<React.SetStateAction<string[]>>;
}

const ShopperVendorsFilter: FunctionComponent<ShopperVendorsFilterProps> = (props) => {
  const { mobile, vendors, selectedVendors, setSelectedVendors } = props;
  vendors.sort((a, b) => (a.name > b.name ? 1 : -1));
  const margin = mobile ? 'dense' : 'normal';

  const handleVendorChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setSelectedVendors([...(event.target.value as string[])]);
  };
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} variant="outlined" margin={margin} fullWidth>
      <InputLabel id="shopper-vendors-filter-label" className={classes.label}>
        Vendor
      </InputLabel>
      <Select
        labelId="vendor-select-label"
        id="s-select"
        value={selectedVendors}
        input={<OutlinedInput />}
        className={classes.values}
        onChange={handleVendorChange}
        renderValue={(selectedVendors) => (selectedVendors as string[]).join(', ')}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        multiple
      >
        {vendors.map(({ name }) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selectedVendors.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ShopperVendorsFilter;
