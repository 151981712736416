import { getShopperStatusOptions, getShopperStatuses, getVendors } from '../api';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import StatusesTable from '../components/StatusesTable';
import EmptyState from '../components/empty';
import Header from '../components/Header';
import { useQuery } from 'react-query';
import * as store from 'store';
import React, { useState } from 'react';
import MallFilter from '../components/MallFilter';
import { useWindowParams } from '../hooks/useWindowParams';
import { BreakPoints } from '../theme/breakPoints';
import ShopperVendorsFilter from '../components/ShopperVendorsFilter';
import { useOrdersContext } from '../contexts/OrdersContext';
import { ShopperStatus } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  spinnerWrapper: {
    height: '60vh',
  },
  filters: {
    objectFit: 'none',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(1),
  },
  filter: {
    gap: '10px',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridTemplateRows: 'repeat(2, 1fr)',
    },
  },
}));

interface Props {
  setSelectedVendors: React.Dispatch<React.SetStateAction<string[]>>;
  vendors: { name: string }[];
  selectedVendors: string[];
}

const StatusFilters: React.FC<Props> = (props) => {
  const { vendors, selectedVendors, setSelectedVendors } = props;
  const { width } = useWindowParams();
  const classes = useStyles();

  const isMobile = width <= BreakPoints.sm;
  return (
    <React.Fragment>
      <Typography>Filter results</Typography>
      <Grid className={classes.filter}>
        <ShopperVendorsFilter
          mobile={isMobile}
          vendors={vendors}
          selectedVendors={selectedVendors}
          setSelectedVendors={setSelectedVendors}
        />
        <MallFilter mobile={isMobile} />
      </Grid>
    </React.Fragment>
  );
};

const ShopperStatuses: React.FC = () => {
  const accessToken = store.get('spotlightAccessToken');
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const { selectedStores } = useOrdersContext();
  const classes = useStyles();

  const { data: statusOptions, isLoading: loadingStatusOptions } = useQuery(['shopperStatusOptions', accessToken], () =>
    getShopperStatusOptions()
  );
  const { data: allVendors, isLoading: loadingVendors } = useQuery(['vendors', accessToken], () => getVendors());
  const { data, isLoading } = useQuery(['shopperStatuses', accessToken], () => getShopperStatuses({ accessToken }));

  const filteredShopperStatuses: ShopperStatus[] = data?.data.details.filter((status: ShopperStatus) => {
    if (selectedVendors.length || selectedStores.length) {
      if (selectedVendors.length && selectedStores.length) {
        return selectedVendors.includes(status.vendor!) && selectedStores.includes(status.shopName!);
      }

      return selectedVendors.length
        ? selectedVendors.includes(status.vendor!)
        : selectedStores.includes(status.shopName!);
    }

    return status;
  });

  const vendors =
    allVendors?.data.details.filter((vendor: string) => vendor !== '').map((vendor: string) => ({ name: vendor })) ??
    [];

  return (
    <React.Fragment>
      <Header activeTab={'shopper-statuses'} />

      <Container maxWidth={false}>
        <Grid container spacing={4}>
          {(!!loadingStatusOptions || !!isLoading || !!loadingVendors) && (
            <EmptyState className={classes.spinnerWrapper} loading title="Loading Shopper Statuses..." />
          )}
          {!loadingStatusOptions && !isLoading && !loadingVendors && (
            <React.Fragment>
              <Grid item xs={12} sm={10} style={{ margin: '1em auto' }}>
                <Paper className={classes.filters}>
                  <StatusFilters
                    vendors={vendors}
                    selectedVendors={selectedVendors}
                    setSelectedVendors={setSelectedVendors}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} style={{ margin: 'auto' }}>
                <StatusesTable
                  shopperStatusOptions={statusOptions?.data.details ?? []}
                  shopperStatuses={filteredShopperStatuses}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default ShopperStatuses;
