import React, { Fragment, FunctionComponent, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { getComments, makeComment } from '../api';
import CustomModal from './CustomModal';
import TextField from '@material-ui/core/TextField';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ListItem } from '@material-ui/core';
import * as store from 'store';
import { createToastMessage } from './toast';

interface CommentsProps {
  orderID: string;
}
interface MakeCommentProps {
  closeComment: () => void;
  openComment: boolean;
  orderID: string;
  refetch: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    list: {
      width: '50%',
      marginRight: theme.spacing(3),
    },

    itemText: {
      maxWidth: '50%',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      float: 'right',
    },
    buttonContaainer: {
      display: 'block',
      height: theme.spacing(7),
    },
    centerAlignDialogActions: {
      justifyContent: 'center',
    },
  })
);

const AddComment: FunctionComponent<MakeCommentProps> = (props) => {
  const { openComment, orderID, closeComment, refetch } = props;
  const classes = useStyles();

  const [comment, setComment] = useState('');
  const accessToken = store.get('spotlightAccessToken');
  const makeCommentMutation = useMutation(makeComment, {
    onSuccess: () => {
      refetch();
      closeComment();
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleMakeComment = () => {
    if (accessToken) {
      makeCommentMutation.mutate(
        { orderID, comment, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };
  return (
    <Fragment>
      <CustomModal openModal={openComment} closeModal={closeComment} title={`Comment on order ${orderID}`}>
        <TextField
          onChange={handleChange}
          margin="normal"
          fullWidth
          id="order-comment"
          label="Comment"
          variant="outlined"
          multiline
          rows={3}
        />

        <MuiDialogActions classes={{ root: classes.centerAlignDialogActions }}>
          <Button variant="contained" color="primary" onClick={handleMakeComment}>
            Save
          </Button>
        </MuiDialogActions>
      </CustomModal>
    </Fragment>
  );
};

const Comments: FunctionComponent<CommentsProps> = (props) => {
  const { orderID } = props;
  const accessToken = store.get('spotlightAccessToken');
  const classes = useStyles();

  const [openComment, setOpenComment] = useState(false);

  let comments = { comments: '' };
  const { data, refetch } = useQuery(['comments', accessToken, orderID], () => getComments({ accessToken, orderID }));
  if (data?.data.details) {
    comments = data?.data.details[0];
  }
  const handleClose = () => {
    setOpenComment(false);
  };
  const handleOpenComment = () => {
    setOpenComment(true);
  };

  const parseComments = (comments: string) => {
    return comments.split(',');
  };

  return (
    <div>
      <div className={classes.buttonContaainer}>
        <Button
          size="large"
          variant="outlined"
          className={classes.button}
          startIcon={<AccountBalanceIcon color="primary" />}
          onClick={handleOpenComment}
        >
          Add comment
        </Button>
      </div>
      <div className={classes.root}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Comments
            </ListSubheader>
          }
        >
          {comments?.comments
            ? parseComments(comments.comments).map((comment) => <ListItem>{comment}</ListItem>)
            : 'No comments'}
          <ListItem></ListItem>
        </List>
      </div>
      {openComment && (
        <AddComment refetch={refetch} orderID={orderID} openComment={openComment} closeComment={handleClose} />
      )}
    </div>
  );
};

export default Comments;
