interface Column {
  accessor: any;
  Header: string;
  webWidth?: number;
  mobileWidth?: number;
  expandingCell?: boolean;
  align?: 'center' | 'left' | 'right';
  format?: (value: number) => string;
  Cell?: any;
  show?: boolean;
  filter?: any;
}

export const AnalyticsColumns: Column[] = [
  {
    accessor: 'timeslot',
    Header: 'Timeslot',
    mobileWidth: 160,
    webWidth: 150,
  },
  {
    accessor: 'completeOrders',
    Header: 'Complete',
    align: 'right',
    mobileWidth: 100,
    webWidth: 60
  },
  {
    accessor: 'incompleteOrders',
    Header: 'Incomplete',
    align: 'right',
    mobileWidth: 100,
    webWidth: 60
  },
];
