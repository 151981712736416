import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useWindowParams } from '../hooks/useWindowParams';
import { BreakPoints } from '../theme/breakPoints';
import { OrderSummary } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      gap: '10px',
      width: '100%',
      display: 'grid',
      height: '100%',
      background: 'transparent',
      gridTemplateRows: '1fr',
      borderRadius: theme.spacing(2),
      color: theme.palette.text.secondary,
      gridTemplateColumns: 'repeat(5, auto)',
      '& svg': {
        margin: theme.spacing(1.5),
      },
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        '& .fullWidth': {
          gridColumn: '2/-1',
        },
      },
    },

    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '165px',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto !important',
      },
    },

    cardContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    title: {
      fontSize: 40,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },

    caption: {
      color: '#95959C',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 13,
        fontWeight: 400,
        letterSpacing: '0.4px',
        textTransform: 'capitalize',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    },
  })
);

interface OrdersSummaryProps {
  ordersSummary: OrderSummary;
  tableInstance: any;
}

const OrdersSummary: FunctionComponent<OrdersSummaryProps> = (props) => {
  const classes = useStyles();

  const { ordersSummary } = props;

  const { width } = useWindowParams();

  const isMobile = width <= BreakPoints.sm;

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2" style={{ color: '#11B0F2' }} className={classes.title} gutterBottom>
            {ordersSummary.totalOrders}
          </Typography>
          <Typography className={classes.caption}>Total Orders</Typography>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2" style={{ color: '#EEAA23' }} className={classes.title} gutterBottom>
            {ordersSummary.inProgress}
          </Typography>
          <Typography className={classes.caption}>In Progress</Typography>
        </CardContent>
      </Card>

      <Card style={{ color: '' }} className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2" style={{ color: '#85CC66' }} className={classes.title} gutterBottom>
            {ordersSummary.totalCompleted}
          </Typography>
          <Typography className={classes.caption}>{!!isMobile ? 'Completed' : 'Completed Orders'}</Typography>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2" style={{ color: '#D65745' }} className={classes.title} gutterBottom>
            {ordersSummary.completedLate}
          </Typography>
          <Typography className={classes.caption}>Completed Late</Typography>
        </CardContent>
      </Card>

      <Card className={`${classes.card} fullWidth`} style={{ backgroundColor: '#D65745' }}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2" style={{ color: '#FFF' }} className={classes.title} gutterBottom>
            {ordersSummary.runningLate}
          </Typography>
          <Typography className={classes.caption} style={{ color: '#FFF' }}>
            Running Late
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrdersSummary;
