import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useWindowParams } from '../../hooks/useWindowParams';
import oc_logo from '../../images/logo.svg';
import ww_logo from '../../images/woolworths.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 250,
    objectFit: 'fill',
    '&.ww': {
      width: 100,
      objectFit: 'contain',
    },
    [theme.breakpoints.down('sm')]: {
      height: 60,
      width: 'auto',
      objectFit: 'contain',
    },
  },
}));

function LoginImage() {
  const classes = useStyles();
  const { isWoolies: isWW } = useWindowParams();
  const login_image = isWW ? ww_logo : oc_logo;
  return (
    <CardMedia
      className={`${isWW ? 'ww' : 'oc'} ${classes.logo}`}
      image={login_image}
      component="img"
      title="Sign in"
    />
  )
}

export default LoginImage;
