import { makeStyles, Theme } from '@material-ui/core/styles';
import { ShopperStatusOption } from '../types/enums';
import { ShopperStatus } from '../types';
import moment from 'moment';
import React from 'react';
import {
  TableContainer,
  Typography,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  AppBar,
  Table,
  Paper,
  Chip,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';

interface StatusesTableProps {
  shopperStatusOptions: {
    shopperStatusOptionID: ShopperStatusOption;
    description: string;
  }[];
  shopperStatuses: ShopperStatus[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => ({ id: `tab-${index}`, 'aria-controls': `tabpanel-${index}` });

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    minHeight: '75vh',
    flexGrow: 1,
  },
  appBar: {
    borderRadius: theme.spacing(2),
    backgroundColor: 'white',
    color: '#44444F',
    fontWeight: 500,
  },
  capitalise: {
    textTransform: 'capitalize',
  },
}));

const StatusesList: React.FC<{ statusOption: ShopperStatusOption; shopperStatuses: ShopperStatus[] }> = (props) => {
  const { statusOption, shopperStatuses } = props;

  const users = shopperStatuses
    .filter(({ status }) => !statusOption || status === statusOption)
    .map((status) => ({
      ...status,
      orderStartTime: status.orderStartTime ? moment(status.orderStartTime).format('hh:mm:ss A') : '–',
    }));

  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{ maxHeight: '75vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="center">Order ID</TableCell>
              <TableCell align="center">Order Start Time</TableCell>
              <TableCell align="center">Login Date Time</TableCell>
              {!statusOption && <TableCell align="center">Shopper Status</TableCell>}
              <TableCell align="center">{statusOption ? 'Status' : 'Visibility'}</TableCell>
              <TableCell align="center">Last Updated</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users.map((user) => (
              <TableRow key={user.userID} hover>
                <TableCell>{user.userID}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell align="center">{user.orderID || '–'}</TableCell>
                <TableCell align="center">{user.orderStartTime}</TableCell>
                <TableCell align="center">{user.loginDateTime}</TableCell>
                {!statusOption && (
                  <TableCell align="center">
                    <Chip size="small" variant="outlined" label={user.statusDescription} color="default" />
                  </TableCell>
                )}
                <TableCell align="center">
                  <Chip
                    size="small"
                    label={user.online ? 'Online' : 'Offline'}
                    color={user.online ? 'primary' : 'secondary'}
                  />
                </TableCell>
                <TableCell align="center">{moment(user.updatedAt).format('DD-MMM-YYYY hh:mm:ss A')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!users.length && (
        <Box p={16}>
          <Typography align="center">No data found!</Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

const StatusesTable: React.FC<StatusesTableProps> = (props) => {
  const { shopperStatusOptions, shopperStatuses } = props;
  const viewAll = { shopperStatusOptionID: 0, description: 'view all' };

  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" variant="scrollable">
          {[viewAll, ...shopperStatusOptions].map(({ shopperStatusOptionID, description }) => (
            <Tab
              key={shopperStatusOptionID}
              label={description}
              {...a11yProps(shopperStatusOptionID)}
              className={classes.capitalise}
            />
          ))}
        </Tabs>
      </AppBar>

      {[viewAll, ...shopperStatusOptions].map(({ shopperStatusOptionID }) => (
        <TabPanel key={shopperStatusOptionID} index={shopperStatusOptionID} value={value}>
          <StatusesList statusOption={shopperStatusOptionID} shopperStatuses={shopperStatuses} />
        </TabPanel>
      ))}
    </div>
  );
};

export default StatusesTable;
