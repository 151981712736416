import React, { FunctionComponent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import { AutoSizer } from 'react-virtualized';
import { useOrdersContext } from '../contexts/OrdersContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down('md')]: {
      gridColumn: '1/-1',
    },
  },
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
  },
  label: {
    backgroundColor: 'white',
  },
  menuPaper: {
    maxHeight: 500,
  },
  values: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

interface StatusSelectProps {
  mobile?: boolean;
}

const StatusSelect: FunctionComponent<StatusSelectProps> = (props) => {
  const classes = useStyles();
  const margin = !props.mobile ? 'normal' : 'dense';

  const { selectedStatus, setSelectedStatus } = useOrdersContext();

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>): void => {
    let values = event.target.value as number[];
    if (values.includes(4)) values = values.filter((x) => x === 4);
    return setSelectedStatus([...values]);
  };

  const statuses = [
    { name: 'In Progress', value: 3 },
    { name: 'Not Started', value: 2 },
    { name: 'Completed', value: 1 },
    { name: 'Cancelled', value: 5 },
    { name: 'Running late', value: 4 },
    { name: 'No Items Found', value: 8 },
    { name: 'On Hold', value: 9 },
    { name: 'Pickup exception', value: 10 },
    { name: 'Drop off exception', value: 11 },
    { name: 'Pickup Started', value: 12 },
    { name: 'Pickup Completed', value: 13 },
  ];

  const statusNames = (selected: unknown) => {
    const selectedNames = (selected as string[]).map((sel) => {
      const status = statuses.find((stat) => stat.value === Number(sel));
      return status?.name;
    });
    return selectedNames.join(', ');
  };

  return (
    <div className={classes.wrapper}>
      <AutoSizer>
        {({ width }) => {
          if (!width || width === 0) width = 150;
          const style = { width: `${width}px`, maxWidth: `${width}px` };
          return (
            <FormControl style={style} variant="outlined" className={classes.formControl} margin={margin} fullWidth>
              <InputLabel className={classes.label} id="status-label">
                Choose Status
              </InputLabel>
              <Select
                labelId="statusselect-label"
                id="status-select"
                multiple
                value={selectedStatus}
                className={classes.values}
                onChange={handleStatusChange}
                input={<OutlinedInput />}
                renderValue={(selected) => statusNames(selected)}
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {statuses.map((stat) => {
                  return (
                    <MenuItem key={stat.name} value={stat.value}>
                      <Checkbox checked={selectedStatus.indexOf(stat.value) > -1} />
                      <ListItemText primary={stat.name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default StatusSelect;
