import { Divider, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import * as store from 'store';
import { getTransactions } from '../api';
import EmptyState from '../components/empty';
import { BreakPoints } from '../theme/breakPoints';

interface TransactionsProps {
  orderID: string;
}

interface Refund {
  date: string;
  refund: number;
}

interface TransactionsType {
  foodForward: number;
  deliveryFee: number;
  serviceFee: number;
  discount: number;
  refund: Refund[];
  netTotal: number;
  subTotal: number;
  orderID: string;
  tips: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.down(BreakPoints.sm)]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    list: {
      width: '50%',
      marginRight: theme.spacing(3),
      [theme.breakpoints.down(BreakPoints.sm)]: {
        width: '100%',
        marginBottom: theme.spacing(3),
        marginRight: 0,
      },
    },

    itemText: {
      maxWidth: '50%',
    },
  })
);

const Transactions: FunctionComponent<TransactionsProps> = (props) => {
  const { orderID } = props;
  const accessToken = store.get('spotlightAccessToken');
  const classes = useStyles();
  let transactions: TransactionsType = {
    deliveryFee: 0,
    discount: 0,
    foodForward: 0,
    netTotal: 0,
    orderID: '',
    refund: [],
    serviceFee: 0,
    subTotal: 0,
    tips: 0,
  };

  const { data, isLoading } = useQuery(['transactions', accessToken, orderID], () =>
    getTransactions({ accessToken, orderID })
  );

  if (data?.data) transactions = data?.data.details;

  if (isLoading) return <EmptyState loading />;

  return (
    <div>
      <div className={classes.root}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Order Cost
            </ListSubheader>
          }
        >
          {transactions.subTotal > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Sub Total: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.subTotal}`} />
            </ListItem>
          )}
          {transactions.deliveryFee > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Delivery Fee: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.deliveryFee}`} />
            </ListItem>
          )}
          {transactions.serviceFee > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Service Fee: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.serviceFee}`} />
            </ListItem>
          )}
          {transactions.tips > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Tip: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.tips}`} />
            </ListItem>
          )}
          <Divider />
          {transactions.netTotal > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} primary="Total: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.netTotal}`} />
            </ListItem>
          )}
        </List>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Refunds
            </ListSubheader>
          }
        >
          {transactions.refund.length > 0
            ? transactions.refund.map((refund) => (
                <ListItem>
                  <ListItemText
                    className={classes.itemText}
                    secondary={refund?.date ? new Date(refund?.date).toDateString() : 'Date not provided'}
                  />
                  <ListItemText className={classes.itemText} primary={`R ${refund.refund}`} />
                </ListItem>
              ))
            : 'No refunds for this order'}
        </List>
      </div>
    </div>
  );
};

export default Transactions;
