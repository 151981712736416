import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import spotlight from '../images/spotlight.png'; // with import
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    transition: 'visibility .5s,opacity .3s linear',
  },
  text: {
    marginTop: theme.spacing(2),
    opacity: 0,
    textTransform: 'uppercase',
    animation: '$loading-text-opacity 2s linear 0s infinite normal',
  },
  image: {
    animation: '2s linear infinite $rotation',
  },

  '@keyframes rotation': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes loading-text-opacity': {
    '0%': {
      opacity: 0,
    },
    '20%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <img alt="loader" className={classes.image} src={spotlight} />
      <Typography className={classes.text} variant="h4">
        Loading...
      </Typography>
    </div>
  );
};

export default Loader;
