import React, { FunctionComponent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getStores } from '../api';
import { useQuery } from 'react-query';
import * as store from 'store';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { AutoSizer } from 'react-virtualized';
import { useOrdersContext } from '../contexts/OrdersContext';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
  },
  label: {
    backgroundColor: 'white',
  },
  menuPaper: {
    maxHeight: 500,
  },
  values: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}));

interface MallSelectProps {
  mobile?: boolean;
}

interface Store {
  name: string;
}

const MallFilter: FunctionComponent<MallSelectProps> = (props) => {
  const accessToken = store.get('spotlightAccessToken');

  const { selectedStores, setSelectedStores } = useOrdersContext();

  let stores: Array<Store> = [];

  const { data } = useQuery<any, Error>(['stores', accessToken], () => getStores({ accessToken }), {
    enabled: !!accessToken,
  });

  if (data?.data) stores = data?.data.details;

  stores.sort((a: Store, b: Store) => (a.name > b.name ? 1 : -1));

  const handleStoreChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setSelectedStores([...(event.target.value as string[])]);
  };

  const classes = useStyles();
  const margin = !props.mobile ? 'normal' : 'dense';

  return (
    <div>
      <AutoSizer>
        {({ width }) => {
          if (!width || width === 0) width = 150;
          const style = { width: `${width}px`, maxWidth: `${width}px` };
          return (
            <FormControl style={style} className={classes.formControl} variant="outlined" margin={margin} fullWidth>
              <InputLabel id="mallfilter-label" className={classes.label}>
                Mall
              </InputLabel>
              <Select
                labelId="store-select-label"
                id="s-select"
                value={selectedStores}
                multiple
                input={<OutlinedInput />}
                className={classes.values}
                onChange={handleStoreChange}
                renderValue={(selectedStores) => (selectedStores as string[]).join(', ')}
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {stores.map((store: Store) => (
                  <MenuItem key={store.name} value={store.name}>
                    <Checkbox checked={selectedStores.indexOf(store.name) > -1} />
                    <ListItemText primary={store.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default MallFilter;
