import { makeStyles } from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import { ReportProblem } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  empty: {
    flex: 1,
    display: 'flex',
    color: '#66758c',
    borderRadius: '2px',
    padding: '64px 32px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Rubik,-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
  },

  icon: {
    fontSize: '48px',
    marginBottom: '16px',
  },

  title: {
    fontSize: '20px',
    margin: '8px auto',
    fontWeight: 500,
  },

  subtitle: {
    fontSize: '16px',
    margin: '8px auto',
  },
}));

interface EmptyStateProps {
  loading?: boolean;
  title?: string;
  className?: any;
}

const EmptyState: FunctionComponent<EmptyStateProps> = (props) => {
  const classes = useStyles();

  const { title = 'Loading...', loading = false, className } = props;

  const Icon = !!loading ? Spinner : ReportProblem;

  return (
    <article className={clsx(classes.empty, className)}>
      <Icon className={classes.icon} />
      <div className={classes.title}>{title}</div>
    </article>
  );
};

export default EmptyState;
