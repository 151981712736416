import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

interface CustomModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => void;
  height?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: FunctionComponent<CustomModalProps> = (props) => {
  const classes = useStyles();
  const { title, children, openModal, closeModal } = props;

  return (
    <Dialog fullScreen open={openModal} onClose={closeModal} TransitionComponent={Transition}>
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{title}</Typography>
        {closeModal ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <MuiDialogContent>{children}</MuiDialogContent>
    </Dialog>
  );
};

export default FullScreenDialog;
