import React from 'react'
import { makeStyles } from '@material-ui/core';
import wooliesLogo from '../images/woolworths.png';

declare interface DriverCellProps {
  name: string
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxHeight: '43px',
    position: 'relative',
    paddingRight: '22px',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
  },
  name: {
    lineHeight: 1.55,
    textTransform: 'capitalize'
  },
  icon: {
    top: 0,
    right: 0,
    width: '20px',
    objectFit: 'contain',
    position: 'absolute',
  }
}))

function DriverCell(props: DriverCellProps) {

  const classes = useStyles();

  const driverName = (props?.name ?? ``).toLowerCase();

  const isWooliesDriver = driverName.includes('woolies');

  const parsedName = driverName.replace('woolies', '') ?? '';

  return (
    <div title={props?.name ?? ``} className={classes.root}>
      <span className={classes.name}>{parsedName}</span>
      {
        !!isWooliesDriver && (
          <img className={classes.icon} src={wooliesLogo} alt="woolworths logo"/>
        )
      }
    </div>
  )
}

export default DriverCell
