import React, { FunctionComponent, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Chip, Paper } from '@material-ui/core';

interface ActivityHistoryFiltersProps {
  activityHistoryData: ActivityHistory[];
  actionTypes: ActionType[];
  setHistory: (history: ActivityHistory[]) => void;
}

interface ActionType {
  actionTypeID: number;
  action: string;
  comment: string;
}

interface ActivityHistory {
  actionID: string;
  actionTypeID: number;
  description: string;
  agentName: string;
  timestamp: Date;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      margin: '20px 0',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      padding: 8,
    },
    btnGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    toggleBtn: {
      borderRadius: '16px !important',
      border: 'none',
      padding: 0,
      margin: 8,
    },
    chip: {
      display: 'inline-block',
      cursor: 'pointer',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      '&>*': {
        verticalAlign: 'sub',
      },
    },
  })
);

const ActivityHistoryFilters: FunctionComponent<ActivityHistoryFiltersProps> = (props) => {
  const classes = useStyles();

  const { activityHistoryData, actionTypes, setHistory } = props;

  const [filters, setFilters] = useState(() => actionTypes.map((o: ActionType) => o.actionTypeID));

  const handleFilters = (event: React.MouseEvent<HTMLElement>, newFilters: number[]) => setFilters(newFilters);

  useEffect(() => {
    const filteredHistory = activityHistoryData.filter((o: ActivityHistory) => filters.includes(o.actionTypeID));

    setHistory(filteredHistory.length ? filteredHistory : activityHistoryData);
  }, [activityHistoryData, filters, setHistory]);

  return (
    <Paper className={classes.root} component="div">
      <b className={classes.title}>Filters</b>

      <ToggleButtonGroup className={classes.btnGroup} onChange={handleFilters} value={filters}>
        {actionTypes.map(({ actionTypeID, action }: ActionType) => {
          return (
            <ToggleButton key={actionTypeID} value={actionTypeID} className={classes.toggleBtn}>
              <Chip
                color={filters.includes(actionTypeID) ? 'primary' : 'default'}
                variant={filters.includes(actionTypeID) ? 'default' : 'outlined'}
                label={action.replace(/_/g, ' ')}
                className={classes.chip}
              />
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Paper>
  );
};

export default ActivityHistoryFilters;
