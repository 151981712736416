import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import { TableInstance } from 'react-table';
import EmptyState from '../components/empty';
import Header from '../components/Header';
import OrdersTable from '../components/OrdersTable';
import { OrderSummary } from '../types';
import { useOrdersContext } from '../contexts/OrdersContext';
// import { ChatContext } from '../contexts/ChatContext';
// Un-comment Chat when chat it ready

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto 1fr',
    },

    paper: {
      height: '100%',
      objectFit: 'none',
      borderRadius: theme.spacing(1),
      color: theme.palette.text.secondary,
      border: '1px solid #E0E0E0',
      padding: theme.spacing(1),
    },

    container: {
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
    },

    wrapper: {
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        padding: '5px',
        paddingTop: '0px',
      },
    },
  })
);

const Orders: React.FC = () => {
  const { orders, result } = useOrdersContext();
  const classes = useStyles();

  const tableInstance = useRef<TableInstance>(null);

  const [ordersSummary, setOrdersSummary] = useState<OrderSummary>({
    totalOrders: 0,
    totalCompleted: 0,
    notStarted: 0,
    runningLate: 0,
    completedLate: 0,
    inProgress: 0,
    cancelled: 0,
  });

  return (
    <Container className={classes.root} maxWidth={false}>
      <Header tableInstance={tableInstance} activeTab={'orders'} summaryData={ordersSummary} />

      <Grid container className={classes.container}>
        <Box p={3} className={classes.wrapper}>
          <Box>
            <Paper className={classes.paper} component={'div'}>
              {!!result.isLoading && <EmptyState loading title="Loading Orders..." />}
              {!result.isLoading && (
                <OrdersTable orders={orders} ref={tableInstance} setOrdersSummary={setOrdersSummary} />
              )}
            </Paper>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

export default Orders;
