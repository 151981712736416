import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable } from 'react-table';
import { generateColumns } from '../../components/VirtualTable';
import { useWindowParams } from '../../hooks/useWindowParams';
import { AnalyticsColumns } from './Columns';

const useTableStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    height: '100%',
    gridColumn: '1 / -1',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    backgroundColor: '#ffffff',
  },
  tbody: {
    width: '100%',
  },
  title: {
    fontWeight: 500,
    lineHeight: '1.5rem',
    padding: '16px 16px 0',
  },
  header: {
    height: '60px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: '#ffffff',
    borderBottomColor: 'rgba(224, 224, 224, 1) !important',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
    },
  },
  row: {
    border: 'none',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    backgroundColor: '#ffffff',
  },
  cell: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
  },
  grid: {
    display: 'grid',
    alignItems: 'center',
    verticalAlign: 'middle',
    gridTemplateRows: 'auto',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    gridTemplateColumns: ({ template }: any) => `${template}`,
    "&:last-child": {
      borderBottomColor: 'transparent'
    }
  },
  tooltip: {
    fontSize: 14,
  },
}));

export const setDynamicColumnWidth = (isMobile: boolean) => {
  return AnalyticsColumns.map(column => {
    const minWidth = isMobile ? column.mobileWidth : column.webWidth;
    return { ...column, minWidth };
  });
};

const Row = ({ classes, data, prepareRow }: any) => {

  prepareRow(data);

  return (
    <TableRow
      {...data.getRowProps()}
      className={`${classes.row} ${classes.grid}`}
      component="div"
      tabIndex={-1}
      hover
    >
      {data.cells.map((cell: any) => {
        return (
          <TableCell
            {...cell.getCellProps()}
            className={`${classes.row} ${classes.cell}`}
            component="div"
            variant="body"
          >
            {cell.render('Cell')}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const TableHeader = (props: any) => {
  const { headerGroups, classes } = props;
  return (
    <TableHead component="div">
      {headerGroups.map((group: any, i: number) => (
        <TableRow {...group.getHeaderGroupProps()} key={i} className={`${classes.grid} ${classes.header}`} component="div">
          {group.headers.map((column: any, i: number) => (
            <TableCell
              {...column.getHeaderProps()}
              className={classes.row}
              component="div"
              variant="head"
              scope="col"
            >
              {column.render('Header')}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableHead>
  );
};

const AnalyticsTable = (props: any) => {
  const [templateColumns, setRowColumns] = useState('');
  const tableContainerRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useWindowParams();

  const instance = useTable(
    {
      data: props.data || [],
      columns: AnalyticsColumns,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: AnalyticsColumns.filter((col: any) => col.show === false).map((col: any) => col.accessor),
      },
    }
  );

  useEffect(() => {
    const columns = setDynamicColumnWidth(isMobile);
    const rect = tableContainerRef?.current?.getBoundingClientRect();
    const template = generateColumns(rect?.width, columns);
    setRowColumns(template);
  }, [isMobile]);

  const classes = useTableStyles({ template: templateColumns });

  const { rows, getTableProps, getTableBodyProps, prepareRow } = instance;

  const data = useMemo(() => ({ items: rows }), [rows]);

  return (
    <TableContainer className={classes.container} ref={tableContainerRef}>
      <div className={`${classes.title}`}>{props.title}</div>
      <Table {...getTableProps()} component="div" stickyHeader aria-label="sticky table">
        <TableHeader headerGroups={instance.headerGroups} classes={classes} template={templateColumns} />
        <TableBody component="div" className={classes.tbody} {...getTableBodyProps()}>
          {
            data.items.map((row: any, i: number) => (
              <Row key={i} data={row} classes={classes} prepareRow={prepareRow} />
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnalyticsTable;
