import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PieChart from '../../components/PieChart';
import { useWindowParams } from '../../hooks/useWindowParams';

interface ChartProps {
  data: {
    percCompletedEarly: number;
    percCompletedOnTime: number;
    percCompletedLate: number;
  };
}

export const useChartStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '4px',
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
  },
  title: {
    fontWeight: 500,
    color: '#505050',
    borderBottom: '1px solid #E2E8F0',
    padding: '10px 20px 12px',
    lineHeight: '1.5',
    fontSize: '17px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 10px',
    justifyContent: 'center',
  },
  chartContainer: {
    width: '100%',
    height: '400px',
    maxWidth: '400px',
  }
}));

function DeliveryStatusChart(props: ChartProps) {
  const classes = useChartStyles();
  const { isMobile } = useWindowParams();
  const { percCompletedLate, percCompletedEarly, percCompletedOnTime } = props?.data ?? {};
  return (
    <div className={classes.root}>
      <div className={classes.title}>Order Delivery Status</div>
      <div className={classes.container}>
        <div className={classes.chartContainer}>
          <PieChart
            colors={['#3182EB', '#00C853', '#F05A69']}
            labels={['(%) On-Time', '(%) Early', '(%) Late']}
            data={[percCompletedOnTime, percCompletedEarly, percCompletedLate]}
            legend={{ position: isMobile ? 'top' : 'right' }}
          />
        </div>
      </div>
    </div>
  )
}

export default DeliveryStatusChart
