import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  containerStyles: {
    width: '100%',
    backgroundColor: '#B0B2B7',
    borderRadius: 5,
  },

  fillerStyles: {
    height: '100%',
    opacity: 2,
    backgroundColor: '#85CC66',
    borderRadius: 'inherit',
    textAlign: 'center',
  },

  labelStyles: {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  },
}));
const ProgressBar = ({ completed }: { completed: number }) => {
  const classes = useStyles();

  return (
    <div className={classes.containerStyles}>
      <div className={classes.fillerStyles} style={{ width: `${completed}%` }}>
        <span className={classes.labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
