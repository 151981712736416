import { useEffect, useState } from 'react';
import { MobileColumns, TabletColumns, WebColumns, LandScapeColumns } from '../components';
import { BreakPoints } from '../theme/breakPoints';
import { useWindowParams } from './useWindowParams';

export function useColumns() {

  const { width } = useWindowParams();

  const [rowSize, setRowSize] = useState(75);

  const [columns, setColumns] = useState(WebColumns);

  useEffect(() => {

    function handleRowSize() {
      //if (width <= BreakPoints.xs) return setRowSize(110);
      return setRowSize(75);
    }

    function handleColumns() {
      if (width <= BreakPoints.sm) {
        
        if (window.innerHeight > window.innerWidth) {
          //Portrait
          return setColumns(MobileColumns);
        } else {
          //Landscape
          return setColumns(LandScapeColumns);
        }
      }

      if (width <= BreakPoints.md) return setColumns(TabletColumns);
      
      return setColumns(WebColumns);
    }

    handleRowSize();
    handleColumns();

  }, [width]);

  return { width, columns, rowSize };

}
