import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: 30,
      height: 30,
      paddingRight: '5px',
      [theme.breakpoints.down(768)]: {
        width: 25,
        height: 25,
      },
    },
  })
);


const StatusIcon = ({ imageUrl, display }: { imageUrl: string; display: boolean }) => {
  const classes = useStyles();
  if (!display) return null;
  return (
    <div style={{ float: 'left' }}>
      <img alt="Status" src={imageUrl} className={classes.icon} />
    </div>
  );
};

export default StatusIcon;
