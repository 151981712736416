import moment from "moment";
import { Order, OrderHighlightingRule } from "../types";
import { OrderHighlightingRuleTarget, OrderHighlightingRuleStatus, OrderStatus } from "../types/enums";
import * as utils from "../utils/indexer";

const OrderStatusRuleIndex = [
  { index: 0, orderStatus: OrderStatus.LATE },
  { index: 0, orderStatus: OrderStatus.INVALID },
  { index: OrderHighlightingRuleStatus.WAITING, orderStatus: OrderStatus.NOT_STARTED },
  { index: OrderHighlightingRuleStatus.SHOPPER_STARTED, orderStatus: OrderStatus.ACTIVE },
  { index: OrderHighlightingRuleStatus.SHOPPER_COMPLETED, orderStatus: OrderStatus.PENDING_DRIVER },
  { index: OrderHighlightingRuleStatus.DRIVER_STARTED, orderStatus: OrderStatus.ON_THE_WAY },
  { index: OrderHighlightingRuleStatus.DRIVER_COMPLETED, orderStatus: OrderStatus.COMPLETED },
  { index: OrderHighlightingRuleStatus.CANCELLED, orderStatus: OrderStatus.CANCELLED },
]

const ordersToSkip = [
  OrderStatus.CANCELLED,
  OrderStatus.NO_ITEMS_FOUND,
  OrderStatus.ON_HOLD,
  OrderStatus.INVALID,
];

function mapRuleToColumn(status: number) {
  switch (status) {
    case OrderHighlightingRuleStatus.SHOPPER_STARTED: return 'startTime'
    case OrderHighlightingRuleStatus.SHOPPER_COMPLETED: return 'shoppingCompleted'
    case OrderHighlightingRuleStatus.DRIVER_STARTED: return 'deliveryStarted'
    case OrderHighlightingRuleStatus.DRIVER_COMPLETED: return 'deliveryCompleted'
    default: return undefined
  }
}

export function getIndexedRagRules(rules: any[]) {

  // group the rule sets by `beforeStatus`
  const [beforeStatusMap] = utils.index(rules, ['beforeStatus']);

  // sort within a grouping
  beforeStatusMap.forEach((values, key) => {
    values.sort((a: any, b: any) => b.priority - a.priority);
    values.forEach((value: OrderHighlightingRule) => {
      value.column = mapRuleToColumn(Number(key) + 1);
      value.statusIndex = OrderStatusRuleIndex.filter(x => {
        return x.index <= value.beforeStatus && x.index >= value.afterStatus
      }).map(x => x.orderStatus);
    });
  });

  return beforeStatusMap;

}

export default function evaluateHighlightingRules(order: Order, ruleSet: Map<string | undefined, any[]>) {

  if (!order || !order?.status) return [];

  if ((ruleSet?.size ?? 0) === 0) return [];

  if (ordersToSkip.includes(Number(order.status))) return [];

  let expectedTimeslot = moment(`${order.deliveryDate}T${order.deliveryFrom}:00+0200`);

  if (moment().isBefore(expectedTimeslot)) return [];

  let applicableRules: Array<{}> = [];

  ruleSet.forEach((rules) => {

    const applicableRule = rules.find((rule: OrderHighlightingRule) => {

      let applicableMoment = moment();

      const applicableColumn = !!rule?.column ? order[rule.column] : undefined;

      if (!!applicableColumn) applicableMoment = moment(`${applicableColumn}+0200`.replace(' ', 'T'));

      if (rule.target === OrderHighlightingRuleTarget.DELIVERY_TO) {
        expectedTimeslot = moment(`${order.deliveryDate}T${order.deliveryTo}:00+0200`);
      };

      return applicableMoment.isSameOrAfter(expectedTimeslot.clone().add(rule.minutesAfter, 'minutes'));

    });

    if (!!applicableRule) {
      const { description: title, colour: color, column } = applicableRule;
      applicableRules.push({ title, color, column });
    }

  });

  return applicableRules;

}
